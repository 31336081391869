import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { FC } from 'react'

import { Button } from '@/components/atoms'
import { CommonButtonProps } from '@/types/interfaces/ui'

interface IProps extends Omit<CommonButtonProps, 'children'> {
  showDetails: boolean
}

const ToggleDetailsButton: FC<IProps> = (props) => {
  const { showDetails, onClick, ...rest } = props

  return (
    <Button
      {...rest}
      type="text"
      endIcon={
        showDetails ? (
          <KeyboardArrowUpOutlinedIcon />
        ) : (
          <KeyboardArrowDownOutlinedIcon />
        )
      }
      onClick={onClick}
    >
      {showDetails ? 'Hide Details' : 'Show Details'}
    </Button>
  )
}

export default ToggleDetailsButton
