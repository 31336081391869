import { SealMatchesPW } from '@/features/gate/enums'

const getSealMatchesPWValue = (
  matches: boolean | undefined | null,
  sealNumber: string[] | undefined
) => {
  if (!sealNumber?.length) return SealMatchesPW.NoSeal

  if (typeof matches !== 'boolean') return SealMatchesPW.NoPW

  return matches ? SealMatchesPW.Yes : SealMatchesPW.No
}

export default getSealMatchesPWValue
