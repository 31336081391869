import { FC, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'

import { Autocomplete, Input } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useCargoAssetFormSectionContext } from '@/features/forms/contexts'
import { prepareFieldID } from '@/features/forms/utils'
import { useGetCargoAssetsOnsiteQuery } from '@/features/gate/api'
import { CargoAsset } from '@/features/yard/types'
import { useStore } from '@/store'

interface IProps {}

interface IAutocompleteProps {
  siteId: string
  autoComplete: boolean
  selectedEventId: string | undefined
  onOptionSelect: (value: CargoAsset | undefined) => void
}

const LicensePlateNumber: FC<IProps | IAutocompleteProps> = (props) => {
  const { autoComplete, siteId, onOptionSelect, selectedEventId } =
    props as IAutocompleteProps

  const {
    control,
    watch,
    formState: { dirtyFields }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { fieldPrefix } = useCargoAssetFormSectionContext()

  const lpnFieldId = prepareFieldID(FORM_IDS.CARGO_ASSET.LPN, fieldPrefix)
  const lpnStateFieldId = prepareFieldID(
    FORM_IDS.CARGO_ASSET.LPN_STATE,
    fieldPrefix
  )

  const assetLpnDebounced = useDebounce(watch(lpnFieldId), DEBOUNCE_TIME)
  const assetLpnState = watch(lpnStateFieldId)

  const { data: assetsByLpn, isFetching } = useGetCargoAssetsOnsiteQuery(
    {
      site_id: siteId,
      org_id: orgId,
      type: 'lpn',
      cargo_asset_lpn_prefix: assetLpnDebounced
    },
    { skip: !autoComplete }
  )

  const customOptionLabel = useCallback((option: CargoAsset) => {
    const { metadata } = option
    const {
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state
    } = metadata

    return cargo_asset_license_plate_state
      ? `${cargo_asset_license_plate_number} (${cargo_asset_license_plate_state})`
      : cargo_asset_license_plate_number
  }, [])

  const getOptionLabel = useCallback(
    (option: CargoAsset) =>
      option?.metadata?.cargo_asset_license_plate_number || '',
    []
  )

  const isOptionSelected = useCallback(
    (option: CargoAsset) => option.id === selectedEventId,
    [selectedEventId]
  )

  // On initial form load, if the LPN is already detected by TVE
  // then select the option from the list based on the LPN and LPN state
  // That should work only for autocomplete mode and before field was edited by user
  useEffect(() => {
    const isFieldTouched = dirtyFields[lpnFieldId]

    if (!autoComplete || !assetLpnDebounced || isFieldTouched) return

    const selectedEvent = assetsByLpn?.find?.(
      (asset) =>
        asset.metadata.cargo_asset_license_plate_number === assetLpnDebounced &&
        asset.metadata.cargo_asset_license_plate_state === assetLpnState
    )

    if (selectedEvent) {
      onOptionSelect(selectedEvent)
    }
  }, [assetsByLpn])

  if (autoComplete) {
    return (
      <Controller
        name={lpnFieldId}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            label="LPN"
            name={field.name}
            inputValue={field.value}
            options={assetsByLpn || []}
            loading={isFetching}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => {
              field.onChange(newValue)
            }}
            getOptionLabel={getOptionLabel}
            customOptionLabel={customOptionLabel}
            isOptionEqualToValue={isOptionSelected}
            onChange={onOptionSelect}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />
    )
  }

  return (
    <Controller
      control={control}
      name={lpnFieldId}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label="LPN"
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default LicensePlateNumber
