import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { FC, PropsWithChildren } from 'react'

import { Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './WarningMessage.module.scss'

const WarningMessage: FC<PropsWithChildren> = (props) => {
  const { children } = props

  return (
    <Row items="center" gap={8} className={styles.warningMessage}>
      <WarningAmberOutlinedIcon />

      <Text
        type={TextTypes.TEXT_XS}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
      >
        {children}
      </Text>
    </Row>
  )
}

export default WarningMessage
