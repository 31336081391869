import { FC, PropsWithChildren } from 'react'

import { Col } from '@/components/atoms'

const FormCol: FC<PropsWithChildren> = (props) => {
  const { children } = props

  return (
    <Col gap={20} items="stretch">
      {children}
    </Col>
  )
}

export default FormCol
