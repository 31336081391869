import * as yup from 'yup'

import { cargoAssetValidations } from '@/features/forms/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

export const CargoAssetDetailsSchema = yup.object({
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes))
    .required('Type is required'),
  account_name: yup.string(),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(CargoAssetTypes))
          .required('Asset type is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup
    .string()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) =>
        schema.max(50, 'Max length is 50').required('ID is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_carrier_name: yup
    .string()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) => schema.required('Carrier is required'),
      otherwise: (schema) => schema
    }),
  chassis_id: yup.string().when(cargoAssetValidations.isChassisVisible.keys, {
    is: cargoAssetValidations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  load_status: yup
    .mixed<LoadTypes>()
    .when(cargoAssetValidations.isVisible.keys, {
      is: cargoAssetValidations.isVisible.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(LoadTypes))
          .required('Load Status is required'),
      otherwise: (schema) => schema
    }),
  shipment_number: yup.string().when(cargoAssetValidations.isNotEmpty.keys, {
    is: cargoAssetValidations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(cargoAssetValidations.isNotEmpty.keys, {
    is: cargoAssetValidations.isNotEmpty.validate,
    then: (schema) => schema.of(yup.string().max(50, 'Max length is 50')),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired(),

  inspection_completed: yup.boolean().notRequired()
})

export type CargoAssetDetailsSchemaType = yup.InferType<
  typeof CargoAssetDetailsSchema
>
