import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME, MIN_LPN_LENGTH, MIN_VIN_LENGTH } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { FailedToLoadFields } from '@/features/forms/types'
import {
  useLazyGetClassAndFuelQuery,
  useLazyGetVinQuery
} from '@/features/gate/api'
import { useStore } from '@/store'
import { FuelTypes, WeightClasses } from '@/types/enums/transactionDetails'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  allowApiCalls?: boolean | ((isDirty: boolean) => boolean)
  setFieldsFailedToLoad?: Dispatch<SetStateAction<FailedToLoadFields>>
}

const VinNumber: FC<IProps | ReadOnlyProps> = (props) => {
  const { setFieldsFailedToLoad, allowApiCalls } = props as IProps
  const { readOnly, customValue, label = 'VIN' } = props as ReadOnlyProps

  const {
    control,
    watch,
    setValue,
    formState: { isDirty }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const [getVin, { isFetching }] = useLazyGetVinQuery()
  const [getClassAndFuelType] = useLazyGetClassAndFuelQuery()

  const lpn = watch(FORM_IDS.POWER_UNIT.LPN)
  const lpnState = watch(FORM_IDS.POWER_UNIT.LPN_STATE)
  const vinNumber = watch(FORM_IDS.POWER_UNIT.VIN)

  const vinDebounced = useDebounce(vinNumber, DEBOUNCE_TIME)
  const lpnDebounced = useDebounce(lpn, DEBOUNCE_TIME)

  const isFetchAllowed = useMemo(() => {
    if (typeof allowApiCalls === 'boolean') return allowApiCalls
    if (typeof allowApiCalls === 'function') return allowApiCalls(isDirty)

    return true
  }, [allowApiCalls, isDirty])

  const fetchAndUpdateVin = async () => {
    const isLpnReady =
      lpnDebounced && lpnDebounced?.length >= MIN_LPN_LENGTH && lpnState

    if (!orgId || !isLpnReady) return

    await getVin({
      org_id: orgId,
      license_plate_number: lpnDebounced,
      state: lpnState
    }).then((response) => {
      const vin = response?.data?.data?.vin || ''

      if (!vin) {
        setFieldsFailedToLoad?.({
          vin: true,
          fuelType: true,
          weightClass: true
        })

        setValue(FORM_IDS.POWER_UNIT.FUEL_TYPE, '' as FuelTypes)
        setValue(FORM_IDS.POWER_UNIT.WEIGHT_CLASS, '' as WeightClasses)
      }

      setValue(FORM_IDS.POWER_UNIT.VIN, vin, { shouldValidate: !!vin })
    })
  }

  const fetchAndUpdateClassAndFuel = async () => {
    const isVinReady = vinDebounced && vinDebounced?.length >= MIN_VIN_LENGTH

    if (!orgId || !isVinReady) return

    await getClassAndFuelType({
      org_id: orgId,
      vin: vinDebounced
    }).then((response) => {
      const vehicleClass = response?.data?.data?.vehicle_class as WeightClasses
      const fuelType = response?.data?.data?.fuel_type as FuelTypes

      setFieldsFailedToLoad?.((prev) => ({
        ...prev,
        vin: false,
        fuelType: !fuelType,
        weightClass: !vehicleClass
      }))

      setValue(FORM_IDS.POWER_UNIT.WEIGHT_CLASS, vehicleClass || '', {
        shouldValidate: !!vehicleClass
      })
      setValue(FORM_IDS.POWER_UNIT.FUEL_TYPE, fuelType || '', {
        shouldValidate: !!fuelType
      })
    })
  }

  useEffect(() => {
    if (isFetchAllowed) {
      fetchAndUpdateVin()
    }
  }, [lpnDebounced, lpnState])

  useEffect(() => {
    if (isFetchAllowed) {
      fetchAndUpdateClassAndFuel()
    }
  }, [vinDebounced])

  if (isFetching) return <ReadOnlyFormValue loading title={label} />

  if (readOnly)
    return <ReadOnlyFormValue title={label} value={customValue || vinNumber} />

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.VIN}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default VinNumber
