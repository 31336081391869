import { baseApi, withParams, withQuery } from '@/api'

import endpoints from './endpoints'
import { ISitesResponse } from '@/features/user-management/api/types'
import { IOrgIdRequest } from '@/types/interfaces/api'
import {
  CheckPermissionRequest,
  CheckPermissionResponse
} from '@/features/auth/types'

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMySites: builder.query<ISitesResponse, IOrgIdRequest>({
      query: ({ orgId }) => {
        return withParams(endpoints.FETCH_MY_SITES, { orgId })
      }
    }),

    checkMyPermissions: builder.query<
      CheckPermissionResponse,
      IOrgIdRequest & CheckPermissionRequest
    >({
      query: ({ orgId, relation, resource }) => {
        return withQuery(withParams(endpoints.CHECK_MY_PERMISSION, { orgId }), {
          resource,
          relation
        })
      },
      keepUnusedDataFor: 30
    })
  })
})

export const { useLazyFetchMySitesQuery, useLazyCheckMyPermissionsQuery } =
  authApi
