export enum TableColumnType {
  Text = 'text',
  Date = 'date',
  Number = 'number',
  Phone = 'phone',
  Actions = 'actions',
  Action = 'action',
  Toggle = 'toggle',
  Chip = 'chip'
}

export enum TableFilterType {
  List = 'list',
  GroupedList = 'groupedList',
  DateRange = 'dateRange',
  Time = 'time'
}

export enum TableActions {
  Delete = 'delete',
  Edit = 'edit',
  Cancel = 'cancel',
  RowClick = 'rowClick',
  ColumnAction = 'columnAction',
  Toggle = 'toggle'
}
