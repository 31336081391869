import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { useCargoAssetFormSectionContext } from '@/features/forms/contexts'
import { prepareFieldID } from '@/features/forms/utils'

const InspectionCompleted = () => {
  const { watch, setValue } = useFormContext()
  const { fieldPrefix } = useCargoAssetFormSectionContext()

  const fieldId = prepareFieldID(
    FORM_IDS.CARGO_ASSET.INSPECTION_COMPLETED,
    fieldPrefix
  )
  const inspectionCompleted = watch(fieldId)

  const onChange = () => setValue(fieldId, !inspectionCompleted)

  return (
    <Checkbox
      value={inspectionCompleted}
      label="Inspection complete"
      onChange={onChange}
    />
  )
}

export default InspectionCompleted
