const gateEndpoints = {
  GET_GATES: '/organizations/:org_id/sites/:site_id/gates',
  GET_LANES: '/organizations/:org_id/sites/:site_id/gates/:gate_id/lanes',
  GET_GATE_TRANSACTIONS:
    '/organizations/:org_id/sites/:site_id/gate-transactions',
  GET_DRIVERS: '/organizations/:org_id/sites/:site_id/drivers-onsite',
  GET_QUEUE_EVENTS: '/organizations/:org_id/lanes/:lane_id/events',
  GET_ACCOUNTS: '/organizations/:org_id/sites/:site_id/merchant-accounts',
  GET_PRESIGNED_URL:
    '/organizations/:org_id/sites/:site_id/events/:event_id/presigned-url',

  CREATE_GATE_TRANSACTION:
    '/organizations/:org_id/sites/:site_id/gates/:gate_id/lanes/:lane_id/events',
  UPDATE_GATE_TRANSACTION:
    '/organizations/:org_id/sites/:site_id/gates/:gate_id/lanes/:lane_id/events',

  DELETE_QUEUE_EVENT:
    '/organizations/:org_id/gates/:gate_id/lanes/:lane_id/events/:id',

  GET_EVENT_DETAILS: '/integrations/global-asset-network-lookup',
  GET_VIN_NUMBER: '/organizations/:org_id/integrations/vin-lookup',
  GET_CLASS_AND_FUEL_DETAILS:
    '/organizations/:org_id/integrations/vehicle-class-and-fuel-details',
  GET_CARRIER: '/organizations/:org_id/integrations/carrier-lookup',
  GET_US_DOT: '/organizations/:org_id/integrations/usdot-lookup',

  GET_POWER_UNITS_ONSITE_BY_ID:
    '/organizations/:org_id/sites/:site_id/power-units-onsite/owner-id-prefix-search',
  GET_POWER_UNITS_ONSITE_BY_LPN:
    '/organizations/:org_id/sites/:site_id/power-units-onsite/lpn-prefix-search',
  GET_CARGO_ASSETS_ONSITE_BY_ID:
    '/organizations/:org_id/sites/:site_id/cargo-assets-onsite/owner-id-prefix-search',
  GET_CARGO_ASSETS_ONSITE_BY_LPN:
    '/organizations/:org_id/sites/:site_id/cargo-assets-onsite/lpn-prefix-search'
}

export default gateEndpoints
