import { FORM_IDS } from '@/features/forms/constants'
import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

export const cargoAssetValidations = {
  isVisible: {
    keys: [FORM_IDS.GENERAL.APPOINTMENT_TYPE, FORM_IDS.POWER_UNIT.TYPE],
    validate: (
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes
    ) => isCargoAssetVisible(appointmentType, powerUnitType)
  },

  isNotMinimized: {
    keys: [FORM_IDS.GENERAL.APPOINTMENT_TYPE, FORM_IDS.POWER_UNIT.TYPE],
    validate: (
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes
    ) =>
      isCargoAssetVisible(appointmentType, powerUnitType) &&
      !isCargoAssetMinimized(powerUnitType)
  },

  isNotEmpty: {
    keys: [
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      FORM_IDS.POWER_UNIT.TYPE,
      FORM_IDS.CARGO_ASSET.LOAD_STATUS
    ],
    validate: (
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes,
      loadStatus: LoadTypes
    ) =>
      isCargoAssetVisible(appointmentType, powerUnitType) &&
      !isAssetEmpty(loadStatus)
  },

  isChassisVisible: {
    keys: [
      FORM_IDS.GENERAL.APPOINTMENT_TYPE,
      FORM_IDS.POWER_UNIT.TYPE,
      FORM_IDS.CARGO_ASSET.TYPE
    ],
    validate: (
      appointmentType: AppointmentTypes,
      powerUnitType: PowerUnitTypes,
      assetType: CargoAssetTypes
    ) =>
      isCargoAssetVisible(appointmentType, powerUnitType) &&
      isChassisIdVisible(powerUnitType, assetType)
  }
}
