import { FC, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'

import { Autocomplete, Input } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useGetPowerUnitsOnsiteQuery } from '@/features/gate/api'
import { PowerUnitOnsite } from '@/features/gate/types'
import { useStore } from '@/store'

interface IProps {}

interface IAutocompleteProps {
  siteId: string
  autoComplete: boolean
  selectedEventId: string | undefined
  onOptionSelect: (value: PowerUnitOnsite | undefined) => void
}

const LicensePlateNumber: FC<IProps | IAutocompleteProps> = (props) => {
  const { autoComplete, siteId, onOptionSelect, selectedEventId } =
    props as IAutocompleteProps

  const {
    control,
    watch,
    formState: { dirtyFields }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const lpnDebounced = useDebounce(
    watch(FORM_IDS.POWER_UNIT.LPN),
    DEBOUNCE_TIME
  )
  const lpnState = watch(FORM_IDS.POWER_UNIT.LPN_STATE)

  const { data: powerUnitsByLpn, isFetching } = useGetPowerUnitsOnsiteQuery(
    {
      site_id: siteId,
      org_id: orgId,
      type: 'lpn',
      power_unit_lpn_prefix: lpnDebounced
    },
    { skip: !autoComplete }
  )

  const getOptionLabel = useCallback(
    (option: PowerUnitOnsite) =>
      option?.metadata?.power_unit_license_plate_number || '',
    []
  )

  const customOptionLabel = useCallback(
    (option: PowerUnitOnsite) =>
      `${option.metadata.power_unit_license_plate_number} (${option.metadata.power_unit_license_plate_state})`,
    []
  )

  const isOptionSelected = useCallback(
    (option: PowerUnitOnsite) => option.id === selectedEventId,
    [selectedEventId]
  )

  // On initial form load, if the LPN is already detected by TVE
  // then select the option from the list based on the LPN and LPN state
  // That should work only for autocomplete mode and before field was edited by user
  useEffect(() => {
    const isFieldTouched = dirtyFields[FORM_IDS.POWER_UNIT.LPN]

    if (!autoComplete || !lpnDebounced || isFieldTouched) return

    const selectedEvent = powerUnitsByLpn?.find?.(
      (pu) =>
        pu.metadata.power_unit_license_plate_number === lpnDebounced &&
        pu.metadata.power_unit_license_plate_state === lpnState
    )

    if (selectedEvent) {
      onOptionSelect(selectedEvent)
    }
  }, [powerUnitsByLpn])

  if (autoComplete) {
    return (
      <Controller
        name={FORM_IDS.POWER_UNIT.LPN}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            required
            label="LPN"
            name={field.name}
            inputValue={field.value}
            options={powerUnitsByLpn || []}
            loading={isFetching}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => field.onChange(newValue)}
            getOptionLabel={getOptionLabel}
            customOptionLabel={customOptionLabel}
            isOptionEqualToValue={isOptionSelected}
            onChange={onOptionSelect}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />
    )
  }

  return (
    <Controller
      control={control}
      name={FORM_IDS.POWER_UNIT.LPN}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          required
          uppercase
          fullWidth
          label="LPN"
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default LicensePlateNumber
