import SearchIcon from '@mui/icons-material/Search'
import { Collapse } from '@mui/material'
import { ChangeEvent, FC, useMemo } from 'react'

import { Col, Input, Row } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'
import {
  FilterDropdown,
  GroupByDropdown
} from '@/components/organisms/Table/components'

import styles from './TableFilters.module.scss'

interface IProps {
  showSearch?: boolean
}

const TableFilters: FC<IProps> = (props) => {
  const { showSearch = false } = props

  const {
    filters,
    filterValues,
    searchValue,
    searchPlaceholder,
    groupByOptions,
    onSearchChange
  } = useTableContext()

  const showFilters = Array.isArray(filters)
  const showGroupBy = Array.isArray(groupByOptions)

  const isFilterApplied = useMemo(
    () =>
      Object.values(filterValues || {}).some((filterValue) => !!filterValue),
    [filterValues]
  )

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value ? e.target.value : undefined)
  }

  return (
    <Col>
      <Row
        justify="between"
        gap={20}
        items="center"
        className={styles.tableFilters}
      >
        {(showFilters || showSearch) && (
          <Row gap={8}>
            {showSearch && (
              <Input
                size="small"
                type="text"
                variant="outlined"
                value={searchValue}
                onChange={onSearch}
                placeholder={searchPlaceholder}
                className="background-color-gray0"
                icon={<SearchIcon width={16} height={16} />}
              />
            )}

            {showFilters && (
              <FilterDropdown isFilterApplied={isFilterApplied} />
            )}
          </Row>
        )}

        {showGroupBy && <GroupByDropdown />}
      </Row>

      <Collapse
        unmountOnExit={false}
        orientation="vertical"
        timeout={400}
        in={isFilterApplied}
      >
        <div id="filters-portal" className="tw-overflow-y-auto" />
      </Collapse>
    </Col>
  )
}

export default TableFilters
