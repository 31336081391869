import { FieldErrors, FieldValues, UseFormTrigger } from 'react-hook-form'

const triggerValidationIfError = (
  fields: string[],
  errors: FieldErrors,
  trigger: UseFormTrigger<FieldValues>
) => {
  const fieldsWithErrors = fields.filter((field) => errors[field])

  if (fieldsWithErrors.length) {
    trigger(fieldsWithErrors)
  }
}

export default triggerValidationIfError
