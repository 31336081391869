import React from 'react'

import { ErrorTemplate, WithHeaderTemplate } from '@/components/templates'
import { EnterpriseLanding } from '@/features/home/component'
import { useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'

const HomeTemplate = () => {
  const { selectedPortal } = useStore((store) => store.user)

  const isEnterprise = selectedPortal?.type === PortalTypes.Enterprise

  return isEnterprise ? (
    <EnterpriseLanding />
  ) : (
    <WithHeaderTemplate title="Home">
      <ErrorTemplate
        title={selectedPortal ? 'No roles assigned' : 'No portals available'}
        description={
          selectedPortal
            ? "You have access to the selected portal, but no roles have been assigned. Please contact your organization's administrator for assistance."
            : "You don't have permission to access any portal. Please contact your organization's administrator for assistance."
        }
      />
    </WithHeaderTemplate>
  )
}

export default HomeTemplate
