import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import { Modal } from '@/components/organisms'
import { FormAside, GeneralFormSection } from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import {
  useGetPresignedUrlQuery,
  useUpdateGateTransactionMutation
} from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  UpdateGateTransactionRequestBody,
  UpdateGateTransactionRequestParams
} from '@/features/gate/api/types'
import { SealMatchesPW } from '@/features/gate/enums'
import {
  getSealMatchesPWValue,
  isAccountVisible,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import { useSnackbar } from '@/features/snackbars-queue'
import { CargoAssetOnsite, CargoAssetOnsitePowerUnit } from '@/features/yard'
import { CargoAsset } from '@/features/yard/types'
import {
  CargoAssetDetailsSchema,
  CargoAssetDetailsSchemaType
} from '@/features/yard/utils'
import { useStore } from '@/store'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { prepareSealNumbers, scrollToFirstFormError } from '@/utils/helpers'
import { sealMatchesMapper } from '@/utils/mappers'

interface IProps extends IModalWithCloseFn {
  item: CargoAsset
}

const EditCargoAssetOnsiteModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { metadata, id, reference_id, lane_display_name, lane_id } = item
  const { mismatch } = metadata

  const { selectedPortal, org } = useStore((store) => store.user)
  const { gate } = useStore((store) => store.gate)
  const { showErrorSnackbar } = useSnackbar()

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id,
      site_id,
      event_id: id
    },
    { skip: !reference_id }
  )
  const [updateGateTransaction, { isLoading }] =
    useUpdateGateTransactionMutation()

  const formReturn = useForm<CargoAssetDetailsSchemaType>({
    resolver: yupResolver(CargoAssetDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,

    defaultValues: {
      date: new Date(item.event_time),
      appointment_type: item.metadata.appointment_type,

      power_unit_type: item.metadata.power_unit_type,
      account_name: item.metadata.account_name?.[0],

      cargo_asset_asset_type: item.metadata.cargo_asset_asset_type,
      cargo_asset_owner_id: item.metadata.cargo_asset_owner_id,
      cargo_asset_carrier_name: item.metadata.cargo_asset_carrier_name,
      chassis_id: item.metadata.chassis_id,
      cargo_asset_license_plate_number:
        item.metadata.cargo_asset_license_plate_number,
      cargo_asset_license_plate_state:
        item.metadata.cargo_asset_license_plate_state,
      load_status: item.metadata.load_status,
      shipment_number: item.metadata.shipment_number,
      seal_numbers: item.metadata.seal_numbers || [],
      seal_matchPW: sealMatchesMapper[item.metadata.seal_matchPW],
      inspection_completed: item.metadata.inspection_completed
    }
  })

  const {
    watch,
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const showCargoAsset = isCargoAssetVisible(
    watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE),
    watch(FORM_IDS.POWER_UNIT.TYPE)
  )

  const onSubmit = async (formData: CargoAssetDetailsSchemaType) => {
    if (!org_id || !site_id) return

    const {
      appointment_type,
      power_unit_type,
      account_name,

      cargo_asset_asset_type,
      cargo_asset_owner_id,
      cargo_asset_carrier_name,
      chassis_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state,
      load_status,
      shipment_number,
      seal_numbers,
      seal_matchPW,
      inspection_completed
    } = formData

    const bodyMetadata: CreateGateTransactionMetadata = {
      ...metadata,
      appointment_type,

      cargo_asset_asset_type: '',
      cargo_asset_owner_id: '',
      cargo_asset_carrier_name: '',
      cargo_asset_license_plate_number: '',
      cargo_asset_license_plate_state: '',

      chassis_id: '',
      load_status: '',
      shipment_number: '',
      seal_numbers: [],
      seal_matchPW: SealMatchesPW.NoSeal,
      inspection_completed: null
    }

    if (isAccountVisible(appointment_type)) {
      bodyMetadata.account_name = account_name ? [account_name] : []
    }

    if (showCargoAsset) {
      bodyMetadata.load_status = load_status || ''
      bodyMetadata.inspection_completed = inspection_completed || null

      if (!isCargoAssetMinimized(power_unit_type)) {
        bodyMetadata.cargo_asset_license_plate_number =
          cargo_asset_license_plate_number || ''
        bodyMetadata.cargo_asset_license_plate_state =
          cargo_asset_license_plate_state || ''
        bodyMetadata.cargo_asset_owner_id = cargo_asset_owner_id || ''
        bodyMetadata.cargo_asset_carrier_name = cargo_asset_carrier_name || ''
        bodyMetadata.cargo_asset_asset_type = cargo_asset_asset_type || ''
      }

      if (!isAssetEmpty(load_status)) {
        bodyMetadata.shipment_number = shipment_number || ''
        bodyMetadata.seal_numbers = prepareSealNumbers(seal_numbers)
        bodyMetadata.seal_matchPW = getSealMatchesPWValue(
          seal_matchPW,
          seal_numbers
        )
      }

      if (isChassisIdVisible(power_unit_type, cargo_asset_asset_type)) {
        bodyMetadata.chassis_id = chassis_id || ''
      }
    }

    if (!gate) {
      showErrorSnackbar('Gate not found')
      return
    }

    const query: UpdateGateTransactionRequestBody = {
      correlation_id: item.correlation_id,
      metadata: bodyMetadata
    }

    const params: UpdateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gate.id,
      lane_id
    }

    const response = await updateGateTransaction({
      params,
      body: query
    })

    if (!response.error) {
      closeModal()
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <Modal
      title="Cargo Asset Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button
          action="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          type="primary"
        >
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!reference_id}
              img={imageUrl}
              laneName={lane_display_name}
            />
          }
        >
          <form>
            <GeneralFormSection />
            <CargoAssetOnsite siteId={site_id} />
            <CargoAssetOnsitePowerUnit item={item} />
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default EditCargoAssetOnsiteModal
