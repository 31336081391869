import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { ReadOnlyFormValue, Select } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { gateApi } from '@/features/gate/api'
import { useStore } from '@/store'
import { WeightClasses } from '@/types/enums/transactionDetails'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import {
  classListSelectOptions,
  weightClassesFriendlyNames
} from '@/utils/mappers'

interface IProps {}

const WeightClass: FC<IProps | ReadOnlyProps> = (props) => {
  const { readOnly, customValue, label = 'Class' } = props as ReadOnlyProps

  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { control, watch } = useFormContext()

  const { isLoading } = useSelector(
    gateApi.endpoints.getClassAndFuel.select({
      org_id: orgId,
      vin: watch(FORM_IDS.POWER_UNIT.VIN)
    })
  )

  const puClass = (customValue ||
    watch(FORM_IDS.POWER_UNIT.WEIGHT_CLASS)) as WeightClasses

  if (isLoading) return <ReadOnlyFormValue loading required title="Class" />

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={!!puClass && weightClassesFriendlyNames[puClass]}
      />
    )
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.WEIGHT_CLASS}
      control={control}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          required
          label={label}
          options={classListSelectOptions}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default WeightClass
