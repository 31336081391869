import { statesOptions } from '@/utils/data'

const convertStateFromTVE = (longId: string | undefined): string => {
  if (!longId) return ''

  const state = statesOptions.find((s) =>
    [s.longId, s.id, s.label].includes(longId)
  )

  return state?.id || ''
}

export default convertStateFromTVE
