const ROUTES = {
  HOME: '/',
  GATE_QUEUE: '/:site_id/gate/queue',
  GATE_DRIVERS: '/:site_id/yard/gate-drivers',
  GATE_TRANSACTIONS: '/:site_id/gate/transactions',
  YARD_CARGO_ASSET: '/:site_id/yard/cargo-asset',
  ISR: '/:site_id/isr',
  ADMIN_MISMATCHES: '/:site_id/administration/mismatches',
  ADMIN_VIDEO: '/:site_id/administration/video',
  USERS: '/enterprise/users',
  PROFILE: '/profile'
}

export default ROUTES
