import { GateQueueEvent } from '@/features/gate/types'
import {
  AppointmentTypes,
  CargoAssetTypes
} from '@/types/enums/transactionDetails'
import { convertStateFromTVE } from '@/utils/helpers'

const getFormValuesFromQueueEvent = (event: GateQueueEvent | undefined) => {
  const commonValues = {
    date: new Date(),
    appointment_type: AppointmentTypes.DROP
  }

  if (!event) return commonValues

  const { metadata } = event

  const {
    power_unit_owner_id,
    power_unit_license_plate_number,
    power_unit_license_plate_state,
    power_unit_carrier_usdot,

    cargo_asset_owner_id,
    cargo_asset_license_plate_number,
    cargo_asset_license_plate_state,
    cargo_asset_asset_type,
    chassis_id,
    shipment_number,
    seal_numbers
  } = metadata

  return {
    ...commonValues,

    power_unit_owner_id: power_unit_owner_id?.[0] || '',
    power_unit_license_plate_number: power_unit_license_plate_number?.[0] || '',
    power_unit_license_plate_state: convertStateFromTVE(
      power_unit_license_plate_state?.[0]
    ),
    power_unit_carrier_usdot: power_unit_carrier_usdot?.[0] || '',

    cargo_asset_owner_id: cargo_asset_owner_id?.[0] || '',
    cargo_asset_license_plate_number:
      cargo_asset_license_plate_number?.[0] || undefined,
    cargo_asset_license_plate_state: convertStateFromTVE(
      cargo_asset_license_plate_state?.[0]
    ),
    cargo_asset_asset_type:
      cargo_asset_asset_type?.[0] === 'TRAILER'
        ? CargoAssetTypes.Trailer
        : undefined,
    chassis_id: chassis_id?.[0] || '',
    shipment_number: shipment_number || '',
    seal_numbers: seal_numbers || []
  }
}

export default getFormValuesFromQueueEvent
