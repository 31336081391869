import {
  AppointmentTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

const isCargoAssetVisible = (
  appointmentType: AppointmentTypes,
  unitType: PowerUnitTypes | undefined
) => {
  const isDropOrLive = [AppointmentTypes.DROP, AppointmentTypes.LIVE].includes(
    appointmentType
  )
  const isVehicle = unitType === PowerUnitTypes.Vehicle

  return isDropOrLive && !isVehicle
}

export default isCargoAssetVisible
