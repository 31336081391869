import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import { ISelectOption } from '@/types/interfaces/ui'
import {
  appointmentTypesSelectOptions,
  carriersSelectOptions,
  classListSelectOptions,
  emissionTypesSelectOptions,
  fuelTypeSelectOptions,
  loadStatusSelectOptions,
  transactionTypesSelectOptions
} from '@/utils/mappers'

const transactionsFilters = (accounts: ISelectOption[]): IFilterItem[] => [
  {
    id: 'transactionTime',
    name: 'Date Range',
    type: TableFilterType.DateRange
  },
  {
    id: 'direction',
    name: 'Direction',
    type: TableFilterType.List,
    placeholder: 'Search Direction',
    options: transactionTypesSelectOptions
  },
  {
    id: 'eventType',
    name: 'Event Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: [
      { id: 'detection', label: 'Detection' },
      { id: 'manual', label: 'Manual' }
    ]
  },
  {
    id: 'appointmentType',
    name: 'Appointment Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: appointmentTypesSelectOptions
  },
  {
    id: 'operatingCarrier',
    name: 'Operating Carrier',
    type: TableFilterType.List,
    placeholder: 'Search Carrier',
    options: carriersSelectOptions
  },
  {
    id: 'puClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    options: classListSelectOptions
  },
  {
    id: 'puFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    options: fuelTypeSelectOptions
  },
  {
    id: 'emissionType',
    name: 'Fuel Emission Type',
    type: TableFilterType.List,
    placeholder: 'Search Emission Type',
    options: emissionTypesSelectOptions
  },
  // asset owner
  {
    id: 'loadStatus',
    name: 'Load Status',
    type: TableFilterType.List,
    placeholder: 'Search Status',
    options: loadStatusSelectOptions
  },
  {
    id: 'account',
    name: 'Account',
    type: TableFilterType.List,
    placeholder: 'Search Account',
    options: accounts
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  }
]

export default transactionsFilters
