import { Table, TableBody, TableContainer } from '@mui/material'
import clsx from 'clsx'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

import { Col, Footer, Loader } from '@/components/atoms'
import { TableContextProvider } from '@/components/contexts'
import { TitleWithDescription } from '@/components/molecules'
import { DEBOUNCE_TIME, PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { TableActions } from '@/types/enums/table'
import { SortQueryFormat } from '@/types/interfaces/api'
import {
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  IHighOrderColumn,
  ITableColumn
} from '@/types/interfaces/table'
import { IPagination, Order } from '@/types/interfaces/ui'

import {
  NoResults,
  TableFilters,
  TableHeader,
  TablePagination,
  TableRow as CustomTableRow
} from './components'
import styles from './Table.module.scss'

interface INoResults {
  title: string
  description: string
}

interface IProps {
  name: string
  loading?: boolean
  columns: (groupBy: string | undefined) => ITableColumn[]
  highOrderColumns?: (groupBy: string | undefined) => IHighOrderColumn[]
  rows: any[]
  idAccessor: string
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  onSearch?: (value: string) => void
  perPage?: number
  currentPage?: number
  totalPages?: number
  totalItems?: number
  onPageChange?: (page: number) => void
  onSortChange?: (sort: SortQueryFormat) => void
  enablePagination?: boolean
  searchPlaceholder?: string
  groupByOptions?: IGroupByItem[]
  EmptyScreen?: ReactNode
  isRowHighlighted?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  noResultsMessage?: INoResults
}

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    columns,
    rows = [],
    idAccessor,
    onSearch,
    onPageChange,
    loading = false,
    currentPage,
    totalPages,
    totalItems,
    onSortChange,
    perPage = PAGINATION_ITEMS_PER_PAGE,
    clickable,
    filters,
    groupByOptions,
    defaultFilters,
    handleAction,
    isRowHighlighted,
    highOrderColumns,
    searchPlaceholder = 'Search',
    enablePagination = true,
    noResultsMessage = {
      title: 'No results found',
      description: 'Items will appear here once they are added'
    },
    EmptyScreen
  } = props

  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})

  const [sortField, setSortField] = useState<string | undefined>()
  const [sortOrder, setSortOrder] = useState<Order>(undefined)

  const [page, setPage] = useState<number>(currentPage || 1)

  const pagination: IPagination = {
    perPage,
    currentPage: page,
    totalItems: totalItems || rows.length,
    totalPages: totalPages || Math.ceil(rows.length / perPage)
  }

  const debouncedSearch = useDebounce(searchValue, DEBOUNCE_TIME)

  const memoCols: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])
  const memoHighOrderCols: IHighOrderColumn[] | undefined = useMemo(
    () => highOrderColumns?.(groupBy),
    [groupBy]
  )

  const memoRows: any[] = useMemo(() => {
    // For client side pagination
    if (rows.length && enablePagination && !onPageChange) {
      const start = (pagination.currentPage - 1) * pagination.perPage
      const end = Math.min(
        pagination.currentPage * pagination.perPage,
        pagination.totalItems
      )

      return rows.slice(start, end)
    }

    return rows
  }, [rows, enablePagination, pagination])

  const { showFilters, showSearch, hasRows, isEmpty } = useMemo(() => {
    const isFilterApplied =
      Object.values(filterValues || {}).some((filterValue) => !!filterValue) ||
      !!debouncedSearch

    return {
      showSearch: !!onSearch,
      showFilters:
        !!onSearch || Array.isArray(groupByOptions) || Array.isArray(filters),

      hasRows: !!memoRows.length && !loading,
      isEmpty: !memoRows.length && !isFilterApplied && !loading
    }
  }, [filterValues, debouncedSearch, memoRows, loading])

  const providerValue = useMemo(
    () => ({
      searchValue,
      filterValues,
      groupBy,
      searchPlaceholder,

      filters,
      groupByOptions,

      isRowHighlighted,
      setFilterValues,
      handleAction,
      onSearchChange: setSearchValue,
      onGroupByChange: setGroupBy
    }),
    [
      searchValue,
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      handleAction,
      isRowHighlighted,
      searchPlaceholder
    ]
  )

  const clearFilters = () => {
    setSearchValue('')
    setFilterValues({})
  }

  const handleSort = (property: string) => {
    if (sortField !== property) {
      setSortField(property)
      setSortOrder('asc')

      onSortChange?.(`${property}:asc`)
    } else if (sortOrder === 'asc') {
      setSortOrder('desc')

      onSortChange?.(`${property}:desc`)
    } else {
      setSortField(undefined)
      setSortOrder(undefined)

      onSortChange?.(undefined)
    }
  }

  const handlePageChange = (value: number) => {
    if (onPageChange) {
      onPageChange(value)
    }

    setPage(value)
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    // Trigger search when search value is empty or min 3 characters
    if (!debouncedSearch || debouncedSearch?.length >= 3) {
      onSearch?.(debouncedSearch || '')
      handlePageChange(1)
    }
  }, [debouncedSearch])

  if (isEmpty) {
    return (
      <>
        {EmptyScreen || (
          <TitleWithDescription
            title={noResultsMessage.title}
            description={noResultsMessage.description}
          />
        )}
      </>
    )
  }

  return (
    <TableContextProvider value={providerValue}>
      <Col
        items="stretch"
        className="tw-self-stretch tw-w-full background-color-gray0"
      >
        {showFilters && <TableFilters showSearch={showSearch} />}

        <div className={clsx(styles.tableWrapper, hasRows && styles.withItems)}>
          <TableContainer className={styles.tableContainer}>
            <Table
              size="medium"
              aria-labelledby={name}
              classes={{
                root: clsx(
                  styles.table,
                  showFilters && styles.borderTop,
                  clickable && styles.clickable
                )
              }}
            >
              <TableHeader
                sortOrder={sortOrder}
                sortField={sortField}
                cols={memoCols}
                highOrderCols={memoHighOrderCols}
                handleSort={handleSort}
              />

              {hasRows && (
                <TableBody>
                  {memoRows.map((row) => (
                    <CustomTableRow
                      key={row[idAccessor]}
                      row={row}
                      columns={memoCols}
                      idAccessor={idAccessor}
                      clickable={clickable}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {enablePagination && hasRows && (
            <Footer>
              <TablePagination
                pagination={pagination}
                onPageChange={handlePageChange}
              />
            </Footer>
          )}
        </div>

        {!hasRows && !loading && <NoResults clearFilters={clearFilters} />}

        {loading && (
          <Col items="center" justify="center" gap={8} className="tw-flex-1">
            <Loader />
          </Col>
        )}
      </Col>
    </TableContextProvider>
  )
}

export default CustomTable
