import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { useCargoAssetFormSectionContext } from '@/features/forms/contexts'
import { prepareFieldID } from '@/features/forms/utils'
import { SealMatchesPW as SealMatches } from '@/features/gate/enums'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { sealMatchesMapper } from '@/utils/mappers'

const SealMatchesPW: FC<ReadOnlyProps> = (props) => {
  const { readOnly, customValue } = props

  const { setValue, watch } = useFormContext()
  const { fieldPrefix } = useCargoAssetFormSectionContext()

  const fieldId = prepareFieldID(
    FORM_IDS.CARGO_ASSET.SEAL_MATCHES_PW,
    fieldPrefix
  )
  const sealMatches = customValue
    ? sealMatchesMapper[customValue as SealMatches]
    : watch(fieldId)

  const onChange = () => setValue(fieldId, !sealMatches)

  return (
    <Checkbox
      disabled={readOnly}
      value={sealMatches}
      label="The seal matches the paperwork"
      onChange={onChange}
    />
  )
}

export default SealMatchesPW
