import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Chip, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

import styles from './SuggestedItems.module.scss'

interface IProps {
  fieldId: string
  suggestedItems: string[]
}

const SuggestedItems: FC<IProps> = (props) => {
  const { suggestedItems, fieldId } = props
  const { watch, setValue } = useFormContext()

  const fieldValue = watch(fieldId)

  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    fieldValue
  )

  const onChange = (value: string) => {
    setSelectedItem(value)

    setValue(fieldId, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  useEffect(() => {
    if (fieldValue !== selectedItem) {
      setSelectedItem(undefined)
    }
  }, [fieldValue])

  if (suggestedItems.length < 2) return null

  return (
    <Row items="center" gap={24} className={styles.suggestedWrapper}>
      <Row items="center" gap={8}>
        <div className={styles.iconWrapper}>
          <AutoAwesomeOutlinedIcon />
        </div>

        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          Suggested
        </Text>
      </Row>

      <Row items="center" gap={8}>
        {suggestedItems.map((item) => (
          <Chip
            withBorder
            key={item}
            label={item}
            type={selectedItem === item ? 'success' : 'default'}
            icon={selectedItem === item ? <CheckOutlinedIcon /> : undefined}
            onClick={() => onChange(item)}
          />
        ))}
      </Row>
    </Row>
  )
}

export default SuggestedItems
