import { enqueueSnackbar, SnackbarProvider } from 'notistack'
import React, { FC, PropsWithChildren, useEffect } from 'react'

import { Snackbar } from '@/features/snackbars-queue'
import { SnackbarStatus } from '@/features/snackbars-queue/enums'
import {
  hideSnackbar,
  updateSnackbarStatus
} from '@/features/snackbars-queue/store'
import { useDispatch, useStore } from '@/store'

const SnackbarQueueProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props

  const dispatch = useDispatch()

  const { snackbarsQueue } = useStore((store) => store.snackbar)

  useEffect(() => {
    snackbarsQueue.forEach((snackbar) => {
      const { id, options, message, status } = snackbar

      if (status === SnackbarStatus.Shown) return

      enqueueSnackbar(message, {
        ...options,
        key: id,
        onExited: () => {
          dispatch(hideSnackbar(id))
        }
      })

      dispatch(updateSnackbarStatus({ id, status: SnackbarStatus.Shown }))
    })
  }, [snackbarsQueue])

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      Components={{
        default: Snackbar,
        success: Snackbar,
        error: Snackbar,
        warning: Snackbar,
        info: Snackbar
      }}
      classes={{
        root: '!tw-min-w-0',
        containerRoot: '!tw-min-w-0'
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default SnackbarQueueProvider
