import { GateTransaction } from '@/features/gate/types'
import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  appointmentTypesFriendlyNames,
  cargoAssetTypesFriendlyNames,
  emissionTypesFriendlyNames,
  fuelTypesFriendlyNames,
  loadTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import {
  arrayToString,
  sealMatchesToBoolean,
  transactionTypeToDirection,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'

const transactionsColumns = (): ITableColumn[] => [
  // TRANSACTION
  {
    id: 'event_time',
    title: 'Transaction Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'transaction_type',
    title: 'Direction',
    type: TableColumnType.Text,
    converter: transactionTypeToDirection,
    sortable: true
  },
  {
    id: 'type',
    title: 'Event Type',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      row.reference_id ? 'Detection' : 'Manual',
    sortable: true
  },
  {
    id: 'metadata.appointment_type',
    title: 'Appointment Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: appointmentTypesFriendlyNames,
    sortable: true
  },

  // POWER UNIT
  {
    id: 'metadata.power_unit_owner_id',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.power_unit_license_plate_number',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.power_unit_license_plate_state',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'metadata.power_unit_carrier_usdot',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.power_unit_carrier_MCNum',
    title: 'MC #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.power_unit_vin',
    title: 'VIN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.power_unit_weight_class',
    title: 'Class',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: weightClassesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.power_unit_fuel_type',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: fuelTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.power_unit_emission_type',
    title: 'Emission',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: emissionTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.power_unit_carrier_name',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.account_name',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },

  // DRIVER DETAILS
  {
    id: 'driver_name',
    title: 'Name',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      `${row.metadata.driver_first_name} ${row.metadata.driver_last_name}`,
    sortable: true
  },
  {
    id: 'metadata.driver_license_number',
    title: 'License',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.driver_phone_number',
    title: 'Phone #',
    type: TableColumnType.Phone,
    sortable: true
  },

  // CARGO ASSET
  {
    id: 'metadata.cargo_asset_owner_id',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_license_plate_number',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_license_plate_state',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_carrier_name',
    title: 'Asset Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_asset_type',
    title: 'Asset Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: cargoAssetTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.load_status',
    title: 'Load Status',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: loadTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.shipment_number',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.seal_numbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },
  {
    id: 'metadata.seal_matchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true
  },
  {
    id: 'metadata.inspection_completed',
    title: 'Inspection Completed',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      row.metadata.inspection_completed ? 'Yes' : '',
    sortable: true
  },
  {
    id: 'created_by',
    title: 'Source',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  }
]

export default transactionsColumns
