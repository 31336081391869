import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { CargoAsset } from '@/features/yard/types'
import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  appointmentTypesFriendlyNames,
  cargoAssetTypesFriendlyNames,
  loadTypesFriendlyNames
} from '@/utils/mappers'
import {
  arrayToString,
  millisecondsToDuration,
  sealMatchesToBoolean,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'

dayjs.extend(duration)

const driversColumns = (): ITableColumn[] => [
  {
    id: 'event_time',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'metadata.appointment_type',
    title: 'Appointment Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: appointmentTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_owner_id',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.chassis_id',
    title: 'Chassis ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_license_plate_number',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_license_plate_state',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_carrier_name',
    title: 'Asset Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.power_unit_carrier_name',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.cargo_asset_asset_type',
    title: 'Asset Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: cargoAssetTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.load_status',
    title: 'Arriving Load Status',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: loadTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'metadata.shipment_number',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'metadata.account_name',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },
  {
    id: 'metadata.seal_number',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },
  {
    id: 'metadata.seal_matchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true
  },
  {
    id: 'dwell',
    title: 'Site Dwell',
    type: TableColumnType.Text,
    sortable: true,
    converter: (row: CargoAsset) =>
      millisecondsToDuration({ dwell: dayjs().diff(row.event_time) }, 'dwell')
  },
  {
    id: 'metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  }
]

export default driversColumns
