import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Gate } from '@/features/gate/types'
import { IGateStore } from '@/features/auth/store/types'

const initialState: IGateStore = {
  gate: undefined
}

export const gateStore = createSlice({
  name: 'gate',
  initialState,
  reducers: {
    saveGate: (state, action: PayloadAction<Gate | undefined>) => {
      state.gate = action.payload
    }
  }
})

export const gateStoreReducer = gateStore.reducer

export const { saveGate } = gateStore.actions
