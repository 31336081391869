import IosShareIcon from '@mui/icons-material/IosShare'
import { useState } from 'react'

import { Button, HeaderActionPortal } from '@/components/atoms'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { UpdateIsrReportModal } from '@/features/isr'
import { useFetchISRRecordsQuery } from '@/features/isr/api'
import { ISRRecord } from '@/features/isr/types'
import {
  isrColumns,
  isrFilters,
  isrGroupBy,
  isrHighOrderColumns
} from '@/features/isr/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import { PaginationQuery, SearchQuery } from '@/types/interfaces/api'

const ISRTemplate = () => {
  const [selectedItem, setSelectedItem] = useState<ISRRecord | undefined>()
  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [{ current_page, items_per_page, search_query }, setQuery] = useState<
    PaginationQuery & SearchQuery
  >({
    current_page: 1,
    items_per_page: PAGINATION_ITEMS_PER_PAGE,
    search_query: ''
  })

  const { data, isFetching } = useFetchISRRecordsQuery({
    org_id,
    site_id,
    current_page,
    items_per_page,
    search_query
  })

  const { visible, openModal, closeModal: close } = useModal(false)

  const closeModal = () => {
    close()
    setSelectedItem(undefined)
  }

  const handleAction = (action: TableActions, row: ISRRecord) => {
    switch (action) {
      case TableActions.RowClick: {
        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <WithHeaderTemplate title="ISR">
      {visible && (
        <UpdateIsrReportModal
          item={selectedItem as ISRRecord}
          closeModal={closeModal}
        />
      )}

      <HeaderActionPortal>
        <Button type="outlined" startIcon={<IosShareIcon />}>
          Export
        </Button>
      </HeaderActionPortal>

      <div className="tw-flex-1 tw-flex">
        <Table
          clickable
          name="isr-records"
          idAccessor="id"
          loading={isFetching}
          rows={data?.data?.isr_records || []}
          filters={isrFilters}
          columns={isrColumns}
          groupByOptions={isrGroupBy}
          handleAction={handleAction}
          highOrderColumns={isrHighOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={items_per_page}
          currentPage={current_page}
          totalPages={data?.pagination?.total_pages}
          totalItems={data?.pagination?.total_items_count}
          onPageChange={(page) =>
            setQuery((prev) => ({ ...prev, current_page: page }))
          }
          onSearch={(value) => {
            setQuery((prev) => ({ ...prev, search_query: value }))
          }}
          noResultsMessage={{
            title: 'No ISR reports',
            description: 'ISR reports will appear here once they are ready.'
          }}
        />
      </div>
    </WithHeaderTemplate>
  )
}

export default ISRTemplate
