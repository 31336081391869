import clsx from 'clsx'
import { Dispatch, ReactNode, SetStateAction, useMemo, useRef } from 'react'

import { Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ITab } from '@/types/interfaces/ui'

import styles from './Tabs.module.scss'

interface IProps<T = string> {
  sticky?: boolean
  active: T | undefined
  setActive: Dispatch<SetStateAction<T | undefined>>
  countAsBadge?: boolean
  tabs: ITab<T>[]
  actions?: ReactNode
  headerClassName?: string
}

const Tabs = <T extends string | undefined = string>(props: IProps<T>) => {
  const {
    tabs,
    sticky,
    active,
    countAsBadge,
    setActive,
    actions,
    headerClassName
  } = props

  const contentRef = useRef<HTMLDivElement>(null)

  const activeTabComponent = useMemo(
    () =>
      active ? tabs.find((tab) => tab.id === active)?.Component || null : null,
    [tabs, active]
  )

  const onSwitchTab = (id: T) => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }

    setActive(id)
  }

  return (
    <div className={styles.tabsWrapper}>
      <Row
        items="stretch"
        className={clsx(
          styles.tabsHeader,
          sticky && styles.sticky,
          headerClassName
        )}
      >
        {tabs.map((tab) => (
          <Row
            key={tab.id}
            gap={4}
            items="center"
            onClick={() => onSwitchTab(tab.id)}
            className={clsx(styles.tabItem, active === tab.id && styles.active)}
          >
            {tab.icon}

            <Text
              type={TextTypes.TEXT_SM}
              color={active === tab.id ? Color.green500 : Color.gray600}
            >
              {tab.title} {!countAsBadge && `(${tab.items})`}
            </Text>

            {countAsBadge && (
              <span className={styles.count}>
                <Text
                  type={TextTypes.TEXT_SM}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.gray700}
                >
                  {tab.items}
                </Text>
              </span>
            )}
          </Row>
        ))}

        {!!actions && <div className={styles.actionsWrapper}>{actions}</div>}
      </Row>

      <div ref={contentRef} className={styles.tabsContent}>
        {activeTabComponent}
      </div>
    </div>
  )
}

export default Tabs
