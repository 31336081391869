import { get } from 'lodash'

import { SealMatchesPW } from '@/features/gate/enums'

const sealMatchesToBoolean = (row: any, id: string) => {
  const value = get(row, id) as SealMatchesPW

  if ([SealMatchesPW.NoSeal, SealMatchesPW.NoPW].includes(value)) return ''

  return value === SealMatchesPW.Yes ? 'Yes' : 'No'
}

export default sealMatchesToBoolean
