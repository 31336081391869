import React, { FC, PropsWithChildren, useContext } from 'react'

interface CargoAssetFormSectionContextValue {
  fieldPrefix: string | undefined
}

const CargoAssetFormSectionContext =
  React.createContext<CargoAssetFormSectionContextValue>(
    {} as CargoAssetFormSectionContextValue
  )

interface IProps {
  value: CargoAssetFormSectionContextValue
}

const CargoAssetFormSectionContextProvider: FC<PropsWithChildren<IProps>> = (
  props
) => {
  const { children, value } = props

  return (
    <CargoAssetFormSectionContext.Provider value={value}>
      {children}
    </CargoAssetFormSectionContext.Provider>
  )
}

const useCargoAssetFormSectionContext = () =>
  useContext(CargoAssetFormSectionContext)

export { CargoAssetFormSectionContextProvider, useCargoAssetFormSectionContext }
