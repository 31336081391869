import { FC, PropsWithChildren } from 'react'

import { Row } from '@/components/atoms'

import styles from './FormRow.module.scss'

const FormRow: FC<PropsWithChildren> = (props) => {
  const { children } = props

  return (
    <Row gap={16} className={styles.formRow}>
      {children}
    </Row>
  )
}

export default FormRow
