import React, { FC, ReactNode, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormSection } from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { CargoAssetFormSectionContextProvider } from '@/features/forms/contexts'
import {
  isAccountVisible,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'

import {
  AssetCarrier,
  ChassisId,
  InspectionCompleted,
  LicensePlateNumber,
  LicensePlateState,
  LoadStatus,
  OwnerId,
  SealMatchesPW,
  SealNumbers,
  ShipmentNumber,
  Type
} from './fields'

interface IChildrenProps {
  isMinimized: boolean
  isChassisVisible: boolean
  isEmpty: boolean
  showAccount: boolean
}

interface IProps {
  title?: string
  fieldPrefix?: string
  alwaysVisible?: boolean
  withoutWrapper?: boolean
  children: FC<IChildrenProps> | ReactNode
}

const CargoAssetFormSection = (props: IProps) => {
  const {
    children,
    fieldPrefix,
    alwaysVisible,
    withoutWrapper = false,
    title = 'Cargo Asset'
  } = props

  const { watch } = useFormContext()

  const appointmentType = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)
  const powerUnitType = watch(FORM_IDS.POWER_UNIT.TYPE)
  const assetType = watch(FORM_IDS.CARGO_ASSET.TYPE)
  const loadStatus = watch(FORM_IDS.CARGO_ASSET.LOAD_STATUS)

  const showCargoAsset = isCargoAssetVisible(appointmentType, powerUnitType)

  const childrenProps = useMemo(
    () => ({
      showAccount: isAccountVisible(appointmentType),
      isMinimized: isCargoAssetMinimized(powerUnitType),
      isChassisVisible: isChassisIdVisible(powerUnitType, assetType),
      isEmpty: isAssetEmpty(loadStatus)
    }),
    [appointmentType, powerUnitType, assetType, loadStatus]
  )

  // Context is used here to automatically pass field prefix to all nested fields
  // instead of doing it manually for each field. Also, context can be extended
  // in the future if needed to pass more data to all fields at once.
  const contextValue = useMemo(() => ({ fieldPrefix }), [fieldPrefix])

  // "alwaysVisible" field is used for ISR report since for that form we
  // don't need to hide Cargo Asset section even if appointment type should not
  // include it. ISR report is independent and doesn't affect transaction itself.
  if (!showCargoAsset && !alwaysVisible) return null

  const content =
    typeof children === 'function' ? children(childrenProps) : children

  return (
    <CargoAssetFormSectionContextProvider value={contextValue}>
      {withoutWrapper ? (
        content
      ) : (
        <FormSection title={title}>{content}</FormSection>
      )}
    </CargoAssetFormSectionContextProvider>
  )
}

CargoAssetFormSection.Type = Type
CargoAssetFormSection.OwnerId = OwnerId
CargoAssetFormSection.AssetCarrier = AssetCarrier
CargoAssetFormSection.LoadStatus = LoadStatus
CargoAssetFormSection.ShipmentNumber = ShipmentNumber
CargoAssetFormSection.InspectionCompleted = InspectionCompleted
CargoAssetFormSection.ChassisId = ChassisId
CargoAssetFormSection.SealNumbers = SealNumbers
CargoAssetFormSection.SealMatchesPW = SealMatchesPW
CargoAssetFormSection.LicensePlateNumber = LicensePlateNumber
CargoAssetFormSection.LicensePlateState = LicensePlateState

export default CargoAssetFormSection
