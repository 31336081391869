export enum PortalTypes {
  Site = 'site-portal',
  Enterprise = 'enterprise'
}

export enum SiteRoles {
  Admin = 'administrator',
  ISRManager = 'isr_manager',
  Gate = 'gate_agent',
  ISRViewer = 'isr_viewer',
  GateViewer = 'gate_viewer'
}

export enum EnterpriseRoles {
  Admin = 'administrator'
}

export enum Permissions {
  Full = 'full',
  Read = 'read'
}
