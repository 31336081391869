import { FC } from 'react'

import { Col, WarningMessage } from '@/components/atoms'
import {
  FormCol,
  FormRow,
  PowerUnitFormSection,
  SuggestedItems
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'

interface IProps {
  suggestedIds: string[]
  suggestedUsdot: string[]
  siteId: string
}

const CheckInPowerUnit: FC<IProps> = (props) => {
  const { suggestedIds, suggestedUsdot, siteId } = props

  return (
    <PowerUnitFormSection>
      {({
        showAccount,
        isUsdotRequired,
        warningMessage,
        setFieldsFailedToLoad
      }) => (
        <FormCol>
          <PowerUnitFormSection.Type />

          <Col gap={7}>
            <PowerUnitFormSection.OwnerId />
            <SuggestedItems
              fieldId={FORM_IDS.POWER_UNIT.OWNER_ID}
              suggestedItems={suggestedIds}
            />
          </Col>

          <Col gap={8}>
            <FormRow>
              <PowerUnitFormSection.UsDOT required={isUsdotRequired} />
              <PowerUnitFormSection.OperatingCarrier
                required={isUsdotRequired}
              />
            </FormRow>

            <SuggestedItems
              fieldId={FORM_IDS.POWER_UNIT.USDOT}
              suggestedItems={suggestedUsdot}
            />
          </Col>

          {!!warningMessage && (
            <WarningMessage>{warningMessage}</WarningMessage>
          )}

          <FormRow>
            <FormRow>
              <PowerUnitFormSection.LicensePlateNumber />
              <PowerUnitFormSection.LicensePlateState />
            </FormRow>

            <PowerUnitFormSection.VinNumber
              setFieldsFailedToLoad={setFieldsFailedToLoad}
            />
          </FormRow>

          <FormRow>
            <PowerUnitFormSection.WeightClass />
            <PowerUnitFormSection.FuelType />
          </FormRow>

          {showAccount && <PowerUnitFormSection.Account siteId={siteId} />}
        </FormCol>
      )}
    </PowerUnitFormSection>
  )
}

export default CheckInPowerUnit
