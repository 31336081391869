import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState
} from 'react'
import { useFormContext } from 'react-hook-form'

import { FormSection } from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { FailedToLoadFields } from '@/features/forms/types'
import {
  getMissingFieldNames,
  isAccountVisible,
  isUsdotAndCarrierRequired
} from '@/features/gate/utils'

import {
  Account,
  FuelType,
  LicensePlateNumber,
  LicensePlateState,
  OperatingCarrier,
  OwnerId,
  Type,
  UsDOT,
  VinNumber,
  WeightClass
} from './fields'

interface IChildrenProps {
  setFieldsFailedToLoad: Dispatch<SetStateAction<FailedToLoadFields>>
  showAccount: boolean
  isUsdotRequired: boolean
  warningMessage: string | undefined
}

interface IProps {
  title?: string
  children: FC<IChildrenProps> | ReactNode
}

const PowerUnitFormSection = (props: IProps) => {
  const { title = 'Power Unit', children } = props
  const { watch } = useFormContext()

  const [fieldsFailedToLoad, setFieldsFailedToLoad] =
    useState<FailedToLoadFields>({
      vin: false,
      fuelType: false,
      weightClass: false
    })

  const appointmentType = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)

  const childrenProps = useMemo(() => {
    const fieldNames = getMissingFieldNames(fieldsFailedToLoad)

    return {
      setFieldsFailedToLoad,

      showAccount: isAccountVisible(appointmentType),
      isUsdotRequired: isUsdotAndCarrierRequired(appointmentType),
      warningMessage:
        fieldNames &&
        `Details couldn't be autofilled. Please enter ${fieldNames}`
    }
  }, [appointmentType, fieldsFailedToLoad, setFieldsFailedToLoad])

  return (
    <FormSection title={title}>
      {typeof children === 'function' ? children(childrenProps) : children}
    </FormSection>
  )
}

PowerUnitFormSection.Type = Type
PowerUnitFormSection.UsDOT = UsDOT
PowerUnitFormSection.OwnerId = OwnerId
PowerUnitFormSection.Account = Account
PowerUnitFormSection.FuelType = FuelType
PowerUnitFormSection.VinNumber = VinNumber
PowerUnitFormSection.WeightClass = WeightClass
PowerUnitFormSection.OperatingCarrier = OperatingCarrier
PowerUnitFormSection.LicensePlateState = LicensePlateState
PowerUnitFormSection.LicensePlateNumber = LicensePlateNumber

export default PowerUnitFormSection
