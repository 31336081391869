import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { useCargoAssetFormSectionContext } from '@/features/forms/contexts'
import { prepareFieldID } from '@/features/forms/utils'
import { ReadOnlyProps } from '@/types/interfaces/ui'

const ChassisId: FC<ReadOnlyProps> = (props) => {
  const { readOnly, customValue, label = 'Chassis ID' } = props

  const { control, watch } = useFormContext()
  const { fieldPrefix } = useCargoAssetFormSectionContext()

  const fieldId = prepareFieldID(FORM_IDS.CARGO_ASSET.CHASSIS_ID, fieldPrefix)
  const chassisId = customValue || watch(fieldId)

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={chassisId} />
  }

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default ChassisId
