import React, { FC } from 'react'

import { TitleWithDescription } from '@/components/molecules'

interface IProps {
  clearFilters: () => void
}

const NoResults: FC<IProps> = (props) => {
  const { clearFilters } = props

  return (
    <TitleWithDescription
      title="No results found."
      description={
        <>
          Try searching or filtering by different criteria.{' '}
          <span
            className="color-green500 tw-cursor-pointer"
            onClick={clearFilters}
          >
            Clear Filters
          </span>
        </>
      }
    />
  )
}

export default NoResults
