import { convertStateFromTVE } from '@/utils/helpers'

const prepareLpnForCard = (
  lpn: string[] | undefined,
  state: string[] | undefined
): string | undefined => {
  if (lpn?.length && state?.length) {
    return `${lpn[0]} (${convertStateFromTVE(state[0])})`
  }

  if (lpn?.length) {
    return lpn[0]
  }

  return undefined
}

export default prepareLpnForCard
