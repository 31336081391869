import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import { isUsdotAndCarrierRequired } from '@/features/gate/utils'
import {
  AppointmentTypes,
  FuelTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

export const DriverDetailsSchema = yup.object({
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  power_unit_owner_id: yup.string().max(50, 'Max length is 50'),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string().required('State is required'),
  power_unit_carrier_name: yup.string().when('appointment_type', {
    is: isUsdotAndCarrierRequired,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),

  power_unit_carrier_usdot: yup.string().when('appointment_type', {
    is: isUsdotAndCarrierRequired,
    then: (schema) =>
      schema
        .test(
          'len',
          'Min length is 3',
          (val) => !!val && val?.toString()?.length >= 3
        )
        .test(
          'len',
          'Max length is 20',
          (val) => !!val && val?.toString()?.length <= 20
        )
        .required('US DOT # is required'),
    otherwise: (schema) => schema
  }),
  power_unit_vin: yup.string().max(20, 'Max length is 20'),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses), 'Class is required')
    .required('Class is required'),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .oneOf(Object.values(FuelTypes), 'Fuel Type is required')
    .required('Fuel Type is required'),
  account_name: yup.string(),

  driver_first_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('First Name is required'),
  driver_last_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Last Name is required'),
  driver_license_number: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver License is required'),
  driver_phone_number: yup
    .string()
    .test('format', 'Not valid phone number', (val) =>
      val
        ? matchIsValidTel(val, {
            onlyCountries: ['US', 'MX', 'CA']
          })
        : true
    )
    .notRequired(),
  driver_license_state: yup.string().required('State is required')
})

export type DriverDetailsSchemaType = yup.InferType<typeof DriverDetailsSchema>
