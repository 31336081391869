import { FieldErrors } from 'react-hook-form'

const scrollToFirstFormError = (errors: FieldErrors) => {
  const fieldNames = Object.keys(errors || {})

  if (!fieldNames.length) return

  const firstErrorFieldKey = fieldNames[0]
  const firstErrorFieldElement =
    document.getElementsByName(firstErrorFieldKey)?.[0]

  if (firstErrorFieldElement) {
    firstErrorFieldElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}

export default scrollToFirstFormError
