import dayjs from 'dayjs'
import { FC, useMemo } from 'react'

import { Chip } from '@/components/atoms'

import styles from './EventDwellChip.module.scss'

interface IProps {
  createdAt: Date
}

const EventDwellChip: FC<IProps> = (props) => {
  const { createdAt } = props

  const { message, warning } = useMemo(() => {
    const dwellTime = dayjs().diff(createdAt)
    const dwellMilliseconds = dayjs.duration(dwellTime, 'millisecond')

    const days = dwellMilliseconds.days()
    const hours = dwellMilliseconds.hours()
    const mins = dwellMilliseconds.minutes()

    if (days) {
      return {
        message: `${days} ${days === 1 ? 'day' : 'days'}`,
        warning: true
      }
    }

    if (hours) {
      return {
        message: `${hours} ${days === 1 ? 'hour' : 'hours'}`,
        warning: true
      }
    }

    if (mins) {
      return {
        message: `${mins} min`,
        warning: false
      }
    }

    return { message: 'Just now', warning: false }
  }, [createdAt])

  return (
    <Chip
      type={warning ? 'warning' : 'default'}
      label={message}
      className={styles.eventDwell}
    />
  )
}

export default EventDwellChip
