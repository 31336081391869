import dayjs from 'dayjs'
import { FC, useMemo } from 'react'

import { Col, ReadOnlyFormValue, Text } from '@/components/atoms'
import { FormSection } from '@/features/forms/components'
import { ISRRecord } from '@/features/isr/types'
import { Color } from '@/styles/palette'
import { DateFormat, FontWeight, TextTypes } from '@/types/enums/ui'
import { formatDate } from '@/utils/helpers'

import styles from './IsrReportGeneralDetails.module.scss'

interface IProps {
  item: ISRRecord
}

const IsrReportGeneralDetails: FC<IProps> = (props) => {
  const { item } = props
  const {
    inbound_event_time,
    outbound_event_time,
    inbound_event_user,
    outbound_event_user
  } = item

  const {
    checkInDateFormatted,
    checkInTimeFormatted,
    checkOutDateFormatted,
    checkOutTimeFormatted
  } = useMemo(() => {
    const checkInEventTime = dayjs(inbound_event_time)
    const checkOutEventTime = dayjs(outbound_event_time)

    return {
      checkInDateFormatted: formatDate(checkInEventTime, DateFormat.DATE),
      checkInTimeFormatted: formatDate(checkInEventTime, DateFormat.TIME),
      checkOutDateFormatted: formatDate(checkOutEventTime, DateFormat.DATE),
      checkOutTimeFormatted: formatDate(checkOutEventTime, DateFormat.TIME)
    }
  }, [])

  return (
    <FormSection title="General">
      <Col gap={40}>
        <Col gap={6}>
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            Check-In
          </Text>

          <div className={styles.visitGeneralItems}>
            <ReadOnlyFormValue title="Date" value={checkInDateFormatted} />
            <ReadOnlyFormValue title="Time" value={checkInTimeFormatted} />
            <ReadOnlyFormValue
              title="Check-In User"
              value={inbound_event_user}
            />
          </div>
        </Col>

        <Col gap={6}>
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            Check-Out
          </Text>

          <div className={styles.visitGeneralItems}>
            <ReadOnlyFormValue title="Date" value={checkOutDateFormatted} />
            <ReadOnlyFormValue title="Time" value={checkOutTimeFormatted} />
            <ReadOnlyFormValue
              title="Check-Out User"
              value={outbound_event_user}
            />
          </div>
        </Col>
      </Col>
    </FormSection>
  )
}

export default IsrReportGeneralDetails
