import { FailedToLoadFields } from '@/features/forms/types'

type FailedToLoadFieldsNames = { [key in keyof FailedToLoadFields]: string }

const getMissingFieldNames = (failedFields: FailedToLoadFields) => {
  const names: FailedToLoadFieldsNames = {
    vin: 'VIN',
    fuelType: 'Fuel Type',
    weightClass: 'Class'
  }

  const failedFieldNames = Object.keys(failedFields)
    .filter((key) => failedFields[key as keyof FailedToLoadFields])
    .map((key) => names[key as keyof FailedToLoadFields])

  if (failedFieldNames.length === 0) {
    return ''
  }

  if (failedFieldNames.length === 1) {
    return failedFieldNames[0]
  }

  // Join the names with commas and add 'and' before the last one
  return `${failedFieldNames.slice(0, -1).join(', ')} and ${
    failedFieldNames[failedFieldNames.length - 1]
  }`
}

export default getMissingFieldNames
