import { useEffect, useState } from 'react'

import { Button, HeaderActionPortal } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useSnackbar } from '@/features/snackbars-queue'
import {
  AddEditUserModal,
  CancelInviteModal,
  DeleteUserModal,
  DisableUserModal,
  NoInvites
} from '@/features/user-management'
import {
  useCancelInviteMutation,
  useDeleteUserMutation,
  useEnableDisableUserMutation,
  useFetchInvitesQuery,
  useFetchUsersQuery
} from '@/features/user-management/api'
import {
  UserManagementMessageType,
  UserManagementModalType
} from '@/features/user-management/enums'
import { IUser, UserManagementTab } from '@/features/user-management/types'
import {
  invitesColumns,
  userManagementColumns
} from '@/features/user-management/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import {
  PaginationQuery,
  SearchQuery,
  SortQuery,
  SortQueryFormat
} from '@/types/interfaces/api'
import { ITab } from '@/types/interfaces/ui'

const UserManagementTemplate = () => {
  const { org, me } = useStore((store) => store.user)
  const { showDefaultSnackbar } = useSnackbar()

  const { organization_id = '' } = org || {}
  const { user_id = '' } = me || {}

  const [selectedItem, setSelectedItem] = useState<IUser | undefined>()
  const [activeTab, setActiveTab] = useState<UserManagementTab | undefined>(
    UserManagementTab.Users
  )

  const [usersQuery, setUsersQuery] = useState<
    PaginationQuery & SearchQuery & SortQuery
  >({
    current_page: 1,
    items_per_page: PAGINATION_ITEMS_PER_PAGE,
    search_query: '',
    sort: undefined
  })

  const { data: usersResponse, isFetching: usersFetching } = useFetchUsersQuery(
    {
      orgId: organization_id,
      search_query: usersQuery.search_query,
      current_page: usersQuery.current_page,
      items_per_page: usersQuery.items_per_page,
      sort: usersQuery.sort
    }
  )

  const { data: invitesResponse, isFetching: invitesFetching } =
    useFetchInvitesQuery({ orgId: organization_id })

  const [enableDisableUser, { isLoading: enableDisableLoading }] =
    useEnableDisableUserMutation()

  const [cancelInvite, { isLoading: cancelLoading }] = useCancelInviteMutation()
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation()

  const commonModal = useModal<UserManagementModalType>(undefined, () =>
    setSelectedItem(undefined)
  )
  const disableModal = useModal<boolean>(false, () =>
    setSelectedItem(undefined)
  )

  const closeModal = (type: 'common' | 'disable' | '') => {
    if (type === 'common') {
      commonModal.closeModal()
    } else {
      disableModal.closeModal()
    }

    setSelectedItem(undefined)
  }

  const onDelete = async () => {
    if (!selectedItem || !organization_id) return

    const response = await deleteUser({
      orgId: organization_id,
      userId: selectedItem.id
    })

    if (response?.data?.data?.success) {
      closeModal('common')
      showDefaultSnackbar(UserManagementMessageType.UserDeleted)
    }
  }

  const onCancel = async () => {
    if (!selectedItem || !organization_id) return

    const response = await cancelInvite({
      orgId: organization_id,
      inviteId: selectedItem.id
    })

    if (response?.data?.data?.success) {
      closeModal('common')
      showDefaultSnackbar(UserManagementMessageType.InvitationCanceled)
    }
  }

  const onDisable = async () => {
    if (!selectedItem?.id || !organization_id) return

    const response = await enableDisableUser({
      disable: true,
      orgId: organization_id,
      userId: selectedItem.id
    })

    if (!response?.data?.errors) {
      closeModal('disable')
      showDefaultSnackbar(UserManagementMessageType.AccessDisabled)
    }
  }

  const onEnable = async (userId: string) => {
    if (!organization_id) return

    const response = await enableDisableUser({
      disable: false,
      orgId: organization_id,
      userId
    })

    if (!response?.data?.errors) {
      showDefaultSnackbar(UserManagementMessageType.AccessEnabled)
    }
  }

  const handleAction = (action: TableActions, item: IUser) => {
    switch (action) {
      case TableActions.Delete: {
        commonModal.openModal(UserManagementModalType.Delete)
        setSelectedItem(item)
        break
      }

      case TableActions.Cancel: {
        commonModal.openModal(UserManagementModalType.CancelInvite)
        setSelectedItem(item)
        break
      }

      case TableActions.Edit:
      case TableActions.RowClick: {
        commonModal.openModal(UserManagementModalType.AddEditUser)
        setSelectedItem(item)
        break
      }

      case TableActions.Toggle: {
        if (item.disabled) {
          onEnable(item.id)
        } else {
          disableModal.openModal()
          setSelectedItem(item)
        }

        break
      }

      default: {
        break
      }
    }
  }

  const onInviteCallback = () => {
    closeModal('common')
    setActiveTab(UserManagementTab.Invites)

    showDefaultSnackbar(UserManagementMessageType.UserInvited)
  }

  const onEditCallback = () => {
    const editedUserId = selectedItem?.id

    closeModal('common')
    showDefaultSnackbar(UserManagementMessageType.UserUpdated)

    if (editedUserId === user_id) {
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }

  const isRowHighlighted = (row: IUser) => row.disabled

  useEffect(() => {
    // If user was activated/deactivated from edit popup we need
    // to pass updated data in form
    if (usersResponse?.data?.members && selectedItem) {
      setSelectedItem(
        usersResponse.data.members.find((user) => user.id === selectedItem.id)
      )
    }
  }, [usersResponse?.data?.members])

  const tabs: ITab<UserManagementTab>[] = [
    {
      id: UserManagementTab.Users,
      title: 'Users',
      items: usersResponse?.pagination?.total_items_count || 0,
      Component: (
        <Table
          clickable
          key="users-table"
          name="users"
          idAccessor="id"
          loading={usersFetching}
          rows={usersResponse?.data?.members || []}
          columns={userManagementColumns}
          handleAction={handleAction}
          isRowHighlighted={isRowHighlighted}
          searchPlaceholder="Search name or email"
          perPage={usersQuery.items_per_page}
          currentPage={usersQuery.current_page}
          totalPages={usersResponse?.pagination?.total_pages}
          totalItems={usersResponse?.pagination?.total_items_count}
          onSortChange={(sort: SortQueryFormat) =>
            setUsersQuery((prev) => ({ ...prev, sort }))
          }
          onPageChange={(page) =>
            setUsersQuery((prev) => ({ ...prev, current_page: page }))
          }
          onSearch={(value) => {
            setUsersQuery((prev) => ({ ...prev, search_query: value }))
          }}
        />
      )
    },
    {
      id: UserManagementTab.Invites,
      title: 'Pending Invites',
      items: invitesResponse?.data?.invites?.length || 0,
      Component: (
        <Table
          key="invites-table"
          name="invites"
          idAccessor="id"
          loading={invitesFetching}
          columns={invitesColumns}
          handleAction={handleAction}
          rows={invitesResponse?.data?.invites || []}
          EmptyScreen={
            <NoInvites
              onClick={() =>
                commonModal.openModal(UserManagementModalType.AddEditUser)
              }
            />
          }
        />
      )
    }
  ]

  return (
    <WithHeaderTemplate title="User Management">
      {commonModal.visible === UserManagementModalType.Delete && (
        <DeleteUserModal
          onDelete={onDelete}
          closeModal={() => closeModal('common')}
          name={selectedItem?.name as string}
          loading={deleteLoading}
        />
      )}

      {commonModal.visible === UserManagementModalType.CancelInvite && (
        <CancelInviteModal
          onCancel={onCancel}
          closeModal={() => closeModal('common')}
          loading={cancelLoading}
          email={selectedItem?.email as string}
        />
      )}

      {commonModal.visible === UserManagementModalType.AddEditUser && (
        <AddEditUserModal
          selectedItem={selectedItem}
          editMode={!!selectedItem}
          closeModal={() => closeModal('common')}
          onEditCallback={onEditCallback}
          onInviteCallback={onInviteCallback}
          toggleActivateStatus={() =>
            handleAction(TableActions.Toggle, selectedItem as IUser)
          }
        />
      )}

      {disableModal.visible && (
        <DisableUserModal
          fullName={(selectedItem?.name || selectedItem?.email) as string}
          firstName={selectedItem?.first_name as string}
          onDisable={onDisable}
          closeModal={() => closeModal('disable')}
          loading={enableDisableLoading}
        />
      )}

      <HeaderActionPortal>
        <Button
          type="primary"
          onClick={() =>
            commonModal.openModal(UserManagementModalType.AddEditUser)
          }
        >
          Invite User
        </Button>
      </HeaderActionPortal>

      <div className="tw-flex-1 tw-flex tw-pt-12">
        <Tabs<UserManagementTab>
          active={activeTab}
          setActive={setActiveTab}
          tabs={tabs}
          headerClassName="tw-px-16"
        />
      </div>
    </WithHeaderTemplate>
  )
}

export default UserManagementTemplate
