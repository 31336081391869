import { FC } from 'react'

import { Col } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow
} from '@/features/forms/components'
import { PowerUnitOnsite } from '@/features/gate/types'
import { arrayToString } from '@/utils/table'

interface IProps {
  item: PowerUnitOnsite
}

const DriverOnsiteCargoAsset: FC<IProps> = (props) => {
  const { item } = props

  const { metadata } = item

  const {
    cargo_asset_asset_type,
    cargo_asset_owner_id,
    cargo_asset_carrier_name,
    load_status,
    shipment_number,
    chassis_id,
    seal_matchPW
  } = metadata

  return (
    <CargoAssetFormSection>
      {({ isMinimized, isEmpty, isChassisVisible }) => (
        <FormCol>
          {!isMinimized && (
            <>
              <CargoAssetFormSection.Type
                readOnly
                customValue={cargo_asset_asset_type}
              />

              <FormRow>
                <CargoAssetFormSection.OwnerId
                  readOnly
                  customValue={cargo_asset_owner_id}
                />
                <CargoAssetFormSection.AssetCarrier
                  readOnly
                  customValue={cargo_asset_carrier_name}
                />
              </FormRow>
            </>
          )}

          <FormRow>
            <CargoAssetFormSection.LoadStatus
              readOnly
              customValue={load_status}
            />
            {!isEmpty && (
              <CargoAssetFormSection.ShipmentNumber
                readOnly
                customValue={shipment_number}
              />
            )}
          </FormRow>

          {!isEmpty && (
            <Col gap={4}>
              <CargoAssetFormSection.SealNumbers
                readOnly
                customValue={arrayToString(metadata, 'seal_numbers')}
              />
              <CargoAssetFormSection.SealMatchesPW
                readOnly
                customValue={seal_matchPW}
              />
            </Col>
          )}

          {isChassisVisible && (
            <CargoAssetFormSection.ChassisId
              readOnly
              customValue={chassis_id}
            />
          )}
        </FormCol>
      )}
    </CargoAssetFormSection>
  )
}

export default DriverOnsiteCargoAsset
