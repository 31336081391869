import { IUserPortalsListItem } from '@/features/auth/types'
import { ROUTES } from '@/router'
import { PortalTypes } from '@/types/enums/global'

interface IProps {
  selectedPortal: IUserPortalsListItem
  allowedLinks: string[]
}

const findPageToRedirect = (props: IProps) => {
  const { selectedPortal, allowedLinks } = props

  // Enterprise portal has landing page so initially user has to be redirected to that page
  if (
    selectedPortal.type === PortalTypes.Enterprise ||
    allowedLinks.length === 0
  ) {
    return ROUTES.HOME
  }

  return allowedLinks[0]
}

export default findPageToRedirect
