import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import { useStore } from '@/store'
import { ISelectOption, ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {
  siteId: string
}

const Account: FC<IProps | ReadOnlyProps> = (props) => {
  const { siteId } = props as IProps
  const { readOnly, customValue, label = 'Account' } = props as ReadOnlyProps

  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { control, watch } = useFormContext()

  const { data: accounts = [] } = useFetchAccountsQuery(
    {
      site_id: siteId!,
      org_id: orgId!
    },
    { skip: readOnly }
  )

  const account = (
    customValue || watch(FORM_IDS.POWER_UNIT.ACCOUNT)
  )?.toString()

  if (readOnly) return <ReadOnlyFormValue title={label} value={account} />

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.ACCOUNT}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          freeSolo={false}
          label={label}
          name={field.name}
          options={accounts}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(value: ISelectOption) => field.onChange(value?.id || '')}
          isOptionEqualToValue={(option: ISelectOption) =>
            option.id === field.value
          }
        />
      )}
    />
  )
}

export default Account
