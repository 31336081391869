import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useCargoAssetFormSectionContext } from '@/features/forms/contexts'
import { prepareFieldID } from '@/features/forms/utils'
import { useGetCarriersQuery } from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps {}

const AssetCarrier: FC<IProps | ReadOnlyProps> = (props) => {
  const { readOnly, customValue, label = 'Carrier' } = props as ReadOnlyProps

  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { control, watch } = useFormContext()
  const { fieldPrefix } = useCargoAssetFormSectionContext()

  const fieldId = prepareFieldID(FORM_IDS.CARGO_ASSET.CARRIER, fieldPrefix)
  const carrierDebounced = useDebounce(watch(fieldId), DEBOUNCE_TIME)

  const { data: carriersResponse, isFetching: carriersLoading } =
    useGetCarriersQuery(
      {
        org_id: orgId,
        partial_name: carrierDebounced || ''
      },
      { skip: readOnly }
    )

  const carriersList = carriersResponse?.data?.carriers || []

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={customValue || carrierDebounced}
      />
    )
  }

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          required
          label={label}
          name={field.name}
          inputValue={field.value}
          options={carriersList || []}
          loading={carriersLoading}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onInputChange={(e, newValue) => field.onChange(newValue)}
          getOptionLabel={(option: Carrier) => option?.name || ''}
          customOptionLabel={(option: Carrier) =>
            `${option.name} (${option.us_dot_number})`
          }
          isOptionEqualToValue={(option: Carrier) =>
            option.name === field.value
          }
          onChange={(value: Carrier) => {
            field.onChange(value?.name || '')
          }}
          inputProps={{
            uppercase: true
          }}
        />
      )}
    />
  )
}

export default AssetCarrier
