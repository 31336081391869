import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import { FuelTypes, WeightClasses } from '@/types/enums/transactionDetails'

export const ISRVisitSchema = yup.object({
  power_unit_owner_id: yup.string().max(50, 'Max length is 50'),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string().required('State is required'),
  power_unit_carrier_usdot: yup
    .string()
    .test(
      'len',
      'Max length is 20',
      (val) => !!val && val?.toString()?.length <= 20
    ),
  power_unit_vin: yup.string().max(20, 'Max length is 20'),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses))
    .required('Class is required'),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .oneOf(Object.values(FuelTypes))
    .required('Fuel Type is required'),
  account_name: yup.string(),

  // Driver Section
  driver_first_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('First Name is required'),
  driver_last_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Last Name is required'),
  driver_license_number: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver License is required'),
  driver_phone_number: yup
    .string()
    .test('format', 'Not valid phone number', (val) =>
      val
        ? matchIsValidTel(val, {
            onlyCountries: ['US', 'MX', 'CA']
          })
        : true
    )
    .notRequired(),
  driver_license_state: yup.string().required('State is required'),

  check_in_cargo_asset_owner_id: yup.string().max(50, 'Max length is 50'),
  check_in_cargo_asset_license_plate_number: yup
    .string()
    .max(20, 'Max length is 20'),
  check_in_cargo_asset_license_plate_state: yup.string(),
  check_in_shipment_number: yup.string().max(50, 'Max length is 50'),
  check_in_seal_numbers: yup
    .array()
    .of(yup.string().max(50, 'Max length is 50')),
  check_in_seal_matchPW: yup.boolean(),

  check_out_cargo_asset_owner_id: yup.string().max(50, 'Max length is 50'),
  check_out_cargo_asset_license_plate_number: yup
    .string()
    .max(20, 'Max length is 20'),
  check_out_cargo_asset_license_plate_state: yup.string(),
  check_out_shipment_number: yup.string().max(50, 'Max length is 50'),
  check_out_seal_numbers: yup
    .array()
    .of(yup.string().max(50, 'Max length is 50')),
  check_out_seal_matchPW: yup.boolean()
})

export type ISRVisitSchemaType = yup.InferType<typeof ISRVisitSchema>
