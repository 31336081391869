import { LaneDirection } from '@/features/gate/enums'

const getLaneDirectionName = (
  direction: LaneDirection,
  oppositeDirection?: boolean
) => {
  const isArriving = direction === LaneDirection.Arriving
  const isDeparting = direction === LaneDirection.Departing

  return isArriving || (isDeparting && oppositeDirection)
    ? 'Check-In'
    : 'Check-Out'
}

export default getLaneDirectionName
