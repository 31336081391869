import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ToggleDetailsButton } from '@/components/molecules'
import {
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { PowerUnitOnsite } from '@/features/gate/types'
import { CheckOutSchemaType } from '@/features/gate/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'

interface IProps {
  siteId: string
}

const CheckOutPowerUnit: FC<IProps> = (props) => {
  const { siteId } = props

  const { trigger, reset } = useFormContext<CheckOutSchemaType>()

  const [showReadOnlyFields, setShowReadOnlyFields] = useState<boolean>(false)
  const [animationInProgress, setAnimationInProgress] = useState<boolean>(false)

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    undefined
  )

  const toggleDetails = () => {
    setShowReadOnlyFields(!showReadOnlyFields)

    if (showReadOnlyFields) {
      setAnimationInProgress(true)
    }
  }

  const populatePowerUnitFields = (
    selectedEvent: PowerUnitOnsite | undefined
  ) => {
    setSelectedEventId(selectedEvent?.id)

    const { metadata } = selectedEvent || {}
    const {
      appointment_type,

      power_unit_type,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot,
      power_unit_carrier_name,
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_vin,
      account_name,

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_license_state,
      driver_phone_number
    } = metadata || {}

    reset(
      (prev) => ({
        ...prev,

        [FORM_IDS.GENERAL.APPOINTMENT_TYPE]:
          appointment_type || AppointmentTypes.DROP,

        [FORM_IDS.POWER_UNIT.TYPE]: power_unit_type,
        [FORM_IDS.POWER_UNIT.OWNER_ID]: power_unit_owner_id || '',
        [FORM_IDS.POWER_UNIT.LPN]: power_unit_license_plate_number || '',
        [FORM_IDS.POWER_UNIT.LPN_STATE]: power_unit_license_plate_state || '',
        [FORM_IDS.POWER_UNIT.USDOT]: power_unit_carrier_usdot || '',
        [FORM_IDS.POWER_UNIT.CARRIER]: power_unit_carrier_name || '',
        [FORM_IDS.POWER_UNIT.WEIGHT_CLASS]: power_unit_weight_class,
        [FORM_IDS.POWER_UNIT.FUEL_TYPE]: power_unit_fuel_type,
        [FORM_IDS.POWER_UNIT.VIN]: power_unit_vin || '',
        [FORM_IDS.POWER_UNIT.ACCOUNT]: account_name?.[0] || '',

        [FORM_IDS.DRIVER_DETAILS.FIRST_NAME]: driver_first_name || '',
        [FORM_IDS.DRIVER_DETAILS.LAST_NAME]: driver_last_name || '',
        [FORM_IDS.DRIVER_DETAILS.DRIVER_ID]: driver_license_number || '',
        [FORM_IDS.DRIVER_DETAILS.LICENSE_STATE]: driver_license_state || '',
        [FORM_IDS.DRIVER_DETAILS.PHONE]: driver_phone_number || ''
      }),
      { keepDirtyValues: true, keepDefaultValues: true, keepSubmitCount: true }
    )

    // Revalidate fields which are not read-only to make sure errors are cleared
    // after selecting a new value
    trigger([
      FORM_IDS.POWER_UNIT.OWNER_ID as keyof CheckOutSchemaType,
      FORM_IDS.POWER_UNIT.LPN as keyof CheckOutSchemaType
    ])
  }

  return (
    <PowerUnitFormSection>
      <FormCol>
        <PowerUnitFormSection.Type readOnly />
        <PowerUnitFormSection.OwnerId
          autoComplete
          siteId={siteId}
          selectedEventId={selectedEventId}
          onOptionSelect={populatePowerUnitFields}
        />

        <FormRow>
          <PowerUnitFormSection.LicensePlateNumber
            autoComplete
            siteId={siteId}
            selectedEventId={selectedEventId}
            onOptionSelect={populatePowerUnitFields}
          />
          <PowerUnitFormSection.LicensePlateState readOnly />
        </FormRow>

        <Collapse
          in={showReadOnlyFields}
          unmountOnExit
          onExited={() => setAnimationInProgress(false)}
          className={clsx(
            animationInProgress && '!tw-mt-[-20px] tw-relative tw-top-[20px]'
          )}
        >
          <FormCol>
            <FormRow>
              <PowerUnitFormSection.UsDOT readOnly />
              <PowerUnitFormSection.OperatingCarrier readOnly />
            </FormRow>

            <FormRow>
              <PowerUnitFormSection.WeightClass readOnly />
              <PowerUnitFormSection.FuelType readOnly />
            </FormRow>

            <FormRow>
              <PowerUnitFormSection.VinNumber readOnly allowApiCalls={false} />
              <PowerUnitFormSection.Account readOnly />
            </FormRow>
          </FormCol>
        </Collapse>

        <ToggleDetailsButton
          showDetails={showReadOnlyFields}
          className="tw-self-start !tw-px-0"
          onClick={toggleDetails}
        />
      </FormCol>
    </PowerUnitFormSection>
  )
}

export default CheckOutPowerUnit
