import { InputAdornment } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import React, { forwardRef, ForwardRefRenderFunction, useState } from 'react'

import { DateInputProps } from '@/types/interfaces/ui'

import classes from '../classes'

const DateInput: ForwardRefRenderFunction<HTMLInputElement, DateInputProps> = (
  props,
  ref
) => {
  const {
    min,
    max,
    icon,
    error,
    required,
    fullWidth,
    placeholder,
    helperText,
    ...restProps
  } = props

  const {
    iconClasses,
    rootClasses,
    inputClasses,
    inputLabelClasses,
    helperTextClasses
  } = classes

  const [open, setOpen] = useState(false)

  const onInputClick = () => {
    // I must use timeout here to firstly close all other date picker and then open the current one
    // There is some logic behind the scene in MUI DatePicker that provides this weird issue
    setTimeout(() => {
      setOpen(() => !open)
    })
  }

  return (
    <DatePicker
      {...restProps}
      ref={ref}
      open={open}
      minDate={min}
      maxDate={max}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          error,
          required,
          placeholder,
          helperText,
          variant: 'standard',
          onClick: onInputClick,
          classes: {
            root: clsx(rootClasses.root, fullWidth ? 'tw-w-full' : 'tw-w-115')
          },
          InputProps: {
            required,
            fullWidth,
            endAdornment: null,
            startAdornment: icon && (
              <InputAdornment classes={iconClasses} position="start">
                {icon}
              </InputAdornment>
            ),
            classes: inputClasses
          },
          InputLabelProps: {
            required,
            classes: inputLabelClasses
          },
          FormHelperTextProps: {
            classes: helperTextClasses
          }
        }
      }}
    />
  )
}

export default forwardRef(DateInput)
