import { FC } from 'react'

import {
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'

interface IProps {
  siteId: string
}

const DriverOnsitePowerUnit: FC<IProps> = (props) => {
  const { siteId } = props

  const allowApiCalls = (isDirty: boolean) => isDirty

  return (
    <PowerUnitFormSection>
      {({ isUsdotRequired, showAccount }) => (
        <FormCol>
          <PowerUnitFormSection.OwnerId />

          <FormRow>
            <PowerUnitFormSection.UsDOT required={isUsdotRequired} />
            <PowerUnitFormSection.OperatingCarrier
              required={isUsdotRequired}
              allowApiCalls={allowApiCalls}
            />
          </FormRow>

          <FormRow>
            <PowerUnitFormSection.LicensePlateNumber />
            <PowerUnitFormSection.LicensePlateState />
          </FormRow>

          <FormRow>
            <PowerUnitFormSection.WeightClass />
            <PowerUnitFormSection.FuelType />
          </FormRow>

          <FormRow>
            <PowerUnitFormSection.VinNumber
              readOnly
              allowApiCalls={allowApiCalls}
            />
            {showAccount && <PowerUnitFormSection.Account siteId={siteId} />}
          </FormRow>
        </FormCol>
      )}
    </PowerUnitFormSection>
  )
}

export default DriverOnsitePowerUnit
