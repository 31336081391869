export enum PermissionResourceType {
  Organization = 'organization',
  Site = 'site',
  Gate = 'gate'
}

export enum OrganizationRelation {
  EnterprisePortal = 'enterprise_portal'
}

export enum SiteRelation {
  GateTransactionsList = 'list_gate_transactions',
  DriversOnsiteList = 'list_drivers_onsite',
  CargoAssetsOnsiteList = 'list_cargo_assets_onsite',
  ISRRecordsList = 'list_isr_records'
}

export enum GateRelation {
  Read = 'read'
}
