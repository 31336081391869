import { EnterpriseRoles, Permissions, SiteRoles } from '@/types/enums/global'
import { ISelectOption } from '@/types/interfaces/ui'

export const enterpriseRolesSelectOptions = [
  { id: EnterpriseRoles.Admin, label: 'Admin' }
]

export const permissionsSelectOptions = [
  { id: Permissions.Full, label: 'Full Access' },
  { id: Permissions.Read, label: 'View Only' }
]

export const getSiteRolesSelectOptions = (
  selectedRole: string | undefined,
  siteRoles: { role: SiteRoles | undefined; access: Permissions | undefined }[]
): ISelectOption[] =>
  [
    {
      id: SiteRoles.Admin,
      label: 'Admin'
    },
    {
      id: SiteRoles.ISRManager,
      label: 'ISR Manager'
    },
    {
      id: SiteRoles.Gate,
      label: 'Gate'
    }
  ].filter(
    (option) =>
      !siteRoles.some(
        (role) => role.role === option.id && role.role !== selectedRole
      )
  )
