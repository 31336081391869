import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useLayoutEffect, useState } from 'react'
import {
  Outlet,
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom'

import { Loader } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { Navigation } from '@/components/organisms'
import { ErrorTemplate } from '@/components/templates'
import {
  useLazyCheckMyPermissionsQuery,
  useLazyFetchMySitesQuery
} from '@/features/auth/api'
import {
  GateRelation,
  OrganizationRelation,
  PermissionResourceType,
  SiteRelation
} from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'
import {
  updatePortals,
  updateSitePermissions,
  updateUserDetails
} from '@/features/auth/store'
import {
  IJwt,
  ISitePortalPermissions,
  PermissionResource
} from '@/features/auth/types'
import { useLazyFetchGatesQuery } from '@/features/gate/api'
import { saveGate } from '@/features/gate/store'
import { ROUTES, useNavigator } from '@/router'
import { useDispatch, useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'
import { ErrorType } from '@/types/enums/ui'
import { findPageToRedirect, getAvailableLinksPerPortal } from '@/utils/helpers'

import styles from './MainTemplate.module.scss'

const MainTemplate = () => {
  const location = useLocation()
  const navigate = useNavigator()
  const { site_id } = useParams()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const { toggleMobileNavigation } = useMobileNavigationContext()
  const { selectedPortal, org } = useStore((store) => store.user)

  const [isFetchingProfileData, setFetchingProfileData] = useState(false)

  const path = location.pathname

  const [fetchMySites] = useLazyFetchMySitesQuery()
  const [checkPermission] = useLazyCheckMyPermissionsQuery()
  const [fetchGates] = useLazyFetchGatesQuery()

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0()

  const login = () => {
    const invitation = searchParams.get('invitation')
    const organization = searchParams.get('organization')

    const appState = {
      returnTo: `${window.location.pathname}${window.location.search}`
    }

    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation
        },
        appState
      })

      return
    }

    loginWithRedirect({ appState })
  }

  const getUserInfo = async () => {
    try {
      setFetchingProfileData(() => true)

      const token = await getAccessTokenSilently({})
      const decodedJwt: IJwt = jwtDecode(token)

      const { org_id: orgId } = decodedJwt

      await dispatch(updateUserDetails(token))

      const sitesResponse = await fetchMySites({
        orgId
      })

      const orgPortalEnabled = await checkPermission({
        orgId,
        relation: OrganizationRelation.EnterprisePortal,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Organization,
          orgId
        )
      })

      await dispatch(
        updatePortals({
          sites: sitesResponse?.data?.data?.sites || [],
          enterpriseEnabled: !!orgPortalEnabled?.data?.data?.has_permission,
          portalIdFromUrl: path.includes('enterprise')
            ? PortalTypes.Enterprise
            : site_id
        })
      )
    } catch {
      await dispatch(
        updatePortals({
          sites: [],
          enterpriseEnabled: false
        })
      )
    } finally {
      setFetchingProfileData(() => false)
    }
  }

  const checkIfUserCanAccessPage = () => {
    if (!selectedPortal?.permissions) return

    // No need to auto redirect if user is on profile page and portal is not changed
    if (location.pathname === ROUTES.PROFILE) return

    const allowedLinks = getAvailableLinksPerPortal(selectedPortal)

    if (!allowedLinks.includes(location.pathname)) {
      navigate.to(
        findPageToRedirect({
          selectedPortal,
          allowedLinks
        })
      )
    }
  }

  const getSitePermissions = async () => {
    if (!selectedPortal || !org) return

    const siteId = selectedPortal.id
    const orgId = org.organization_id

    const siteResource = preparePermissionResourceQuery(
      PermissionResourceType.Site,
      siteId
    )

    const gateResponse = await fetchGates({
      org_id: orgId,
      site_id: siteId
    })

    const gate = gateResponse?.data?.data?.gates?.[0]
    const gateId = gate?.id

    if (gate) {
      await dispatch(saveGate(gate))
    }

    const permissionsToCheck: {
      [key in keyof ISitePortalPermissions]?: {
        relation: string
        resource: PermissionResource
      }
    } = {
      gate_transaction: {
        relation: SiteRelation.GateTransactionsList,
        resource: siteResource
      },
      onsite_driver: {
        relation: SiteRelation.DriversOnsiteList,
        resource: siteResource
      },
      onsite_cargo_asset: {
        relation: SiteRelation.CargoAssetsOnsiteList,
        resource: siteResource
      },
      isr: {
        relation: SiteRelation.ISRRecordsList,
        resource: siteResource
      }
    }

    if (gateId) {
      permissionsToCheck.gate_queue = {
        relation: GateRelation.Read,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Gate,
          gateId
        )
      }
    }

    const permissionsResponse = await Promise.all(
      Object.entries(permissionsToCheck).map(([, permissionRequest]) =>
        checkPermission({
          orgId,
          relation: permissionRequest.relation,
          resource: permissionRequest.resource
        })
      )
    )

    const permissions: ISitePortalPermissions = Object.keys(
      permissionsToCheck
    ).reduce(
      (result: ISitePortalPermissions, permissionKey, index: number) => {
        result[permissionKey as keyof ISitePortalPermissions] =
          permissionsResponse?.[index]?.data?.data?.has_permission || false

        return result
      },
      {
        gate_queue: false,
        gate_transaction: false,
        onsite_driver: false,
        onsite_cargo_asset: false,
        isr: false
      }
    )

    await dispatch(updateSitePermissions({ portalId: siteId, permissions }))
  }

  useEffect(() => {
    if (isLoading) return

    if (isAuthenticated) {
      getUserInfo()
      return
    }

    login()
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (selectedPortal && !selectedPortal.permissions) {
      getSitePermissions()
    }
  }, [selectedPortal])

  useLayoutEffect(() => {
    checkIfUserCanAccessPage()
  }, [location.pathname, selectedPortal])

  useEffect(() => {
    toggleMobileNavigation(false)
  }, [location.pathname])

  // If user has access to any portal we need to wait for the roles to be fetched
  if (
    !isAuthenticated ||
    isFetchingProfileData ||
    !selectedPortal?.permissions
  ) {
    return <Loader fullScreen />
  }

  return (
    <div className={styles.wrapper}>
      <Navigation />

      <Sentry.ErrorBoundary
        key={path}
        fallback={<ErrorTemplate type={ErrorType.Generic} />}
      >
        <Outlet />
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default MainTemplate
