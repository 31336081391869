import { yupResolver } from '@hookform/resolvers/yup'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import React, { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  DriverDetailsFormSection,
  FormAside,
  GeneralFormSection
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { CheckOutCargoAsset, CheckOutPowerUnit } from '@/features/gate'
import {
  useCreateGateTransactionMutation,
  useGetPresignedUrlQuery
} from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  CreateGateTransactionRequestBody,
  CreateGateTransactionRequestParams
} from '@/features/gate/api/types'
import {
  DomainEventSchema,
  DomainEventTypes,
  DomainTransactionTypes,
  LaneDirection,
  SealMatchesPW
} from '@/features/gate/enums'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import {
  CheckOutSchema,
  CheckOutSchemaType,
  getFormValuesFromQueueEvent,
  getSealMatchesPWValue,
  isAccountVisible,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import { useStore } from '@/store'
import {
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import { prepareSealNumbers, scrollToFirstFormError } from '@/utils/helpers'
import { emissionTypeByFuelMapper } from '@/utils/mappers'

interface IProps {
  item?: GateQueueEvent
  lane: Lane
  gateId: string
  closeModal: (removeFromMovedItems?: boolean) => void
  onSwapLane: (
    item: GateQueueEvent,
    to: LaneDirection,
    reopenModal?: boolean
  ) => void
}

const CheckOutModal: FC<IProps> = (props) => {
  const { item, gateId, lane, closeModal, onSwapLane } = props

  const { selectedPortal, org } = useStore((store) => store.user)

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const [createGateTransaction, { isLoading }] =
    useCreateGateTransactionMutation()

  const { data: imageUrl } = useGetPresignedUrlQuery(
    { org_id, site_id, event_id: item?.id || '' },
    { skip: !item }
  )

  const formReturn = useForm<CheckOutSchemaType>({
    resolver: yupResolver(CheckOutSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: getFormValuesFromQueueEvent(item),
    shouldFocusError: false
  })

  const {
    watch,
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const showCargoAsset = isCargoAssetVisible(
    watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE),
    watch(FORM_IDS.POWER_UNIT.TYPE)
  )

  const onSubmit = async (formData: CheckOutSchemaType) => {
    if (!org_id || !site_id) return

    const {
      appointment_type,

      power_unit_type,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot,
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_carrier_name,
      power_unit_vin,
      account_name,

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number,
      driver_license_state,

      cargo_asset_owner_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state,
      cargo_asset_carrier_name,
      cargo_asset_asset_type,
      load_status,
      shipment_number,
      seal_numbers,
      chassis_id,
      seal_matchPW
    } = formData

    const metadata: CreateGateTransactionMetadata = {
      lane_id: lane.id,
      transaction_type: DomainTransactionTypes.CheckOut,
      appointment_type,

      power_unit_type: power_unit_type || '',
      power_unit_owner_id: power_unit_owner_id || '',
      power_unit_license_plate_number,
      power_unit_license_plate_state: power_unit_license_plate_state || '',

      power_unit_carrier_usdot: power_unit_carrier_usdot || '',
      power_unit_carrier_name: power_unit_carrier_name || '',
      power_unit_carrier_MCNum:
        item?.metadata?.power_unit_carrier_MCNum?.[0] || '',

      power_unit_vin: power_unit_vin || '',
      power_unit_weight_class: power_unit_weight_class || '',
      power_unit_fuel_type: power_unit_fuel_type || '',
      power_unit_emission_type: power_unit_fuel_type
        ? emissionTypeByFuelMapper[power_unit_fuel_type]
        : '',
      account_name: [],

      driver_first_name: driver_first_name || '',
      driver_last_name: driver_last_name || '',
      driver_license_number: driver_license_number || '',
      driver_phone_number: driver_phone_number || '',
      driver_license_state: driver_license_state || '',

      cargo_asset_owner_id: '',
      cargo_asset_license_plate_number: '',
      cargo_asset_license_plate_state: '',
      cargo_asset_carrier_name: '',
      cargo_asset_asset_type: '',
      load_status: '',
      shipment_number: '',
      seal_numbers: [],
      seal_matchPW: SealMatchesPW.NoSeal,
      chassis_id: '',

      mismatch: false,
      inspection_completed: null
    }

    if (isAccountVisible(appointment_type)) {
      metadata.account_name = account_name ? [account_name] : []
    }

    if (showCargoAsset) {
      metadata.load_status = load_status as LoadTypes

      if (!isCargoAssetMinimized(power_unit_type as PowerUnitTypes)) {
        metadata.cargo_asset_license_plate_number =
          cargo_asset_license_plate_number as string
        metadata.cargo_asset_license_plate_state =
          cargo_asset_license_plate_state as string
        metadata.cargo_asset_owner_id = cargo_asset_owner_id as string
        metadata.cargo_asset_carrier_name = cargo_asset_carrier_name || ''
        metadata.cargo_asset_asset_type = cargo_asset_asset_type || ''
      }

      if (!isAssetEmpty(load_status as LoadTypes)) {
        metadata.shipment_number = shipment_number || ''
        metadata.seal_numbers = prepareSealNumbers(seal_numbers)
        metadata.seal_matchPW = getSealMatchesPWValue(
          seal_matchPW,
          seal_numbers
        )
      }

      if (
        isChassisIdVisible(
          power_unit_type as PowerUnitTypes,
          cargo_asset_asset_type as CargoAssetTypes
        )
      ) {
        metadata.chassis_id = chassis_id || ''
      }
    }

    const query: CreateGateTransactionRequestBody = {
      reference_id: item?.correlation_id || '',
      transaction_type: DomainTransactionTypes.CheckOut,
      type: DomainEventTypes.ManualCheckOut,
      schema: DomainEventSchema.September2024,
      metadata,
      metadata_raw: item?.metadata?.AssetChainEvent || {}
    }

    const params: CreateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gateId,
      lane_id: lane.id
    }

    const response = await createGateTransaction({
      params,
      body: query
    })

    if (!response.error) {
      closeModal(true)
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <Modal
      title="Check-Out"
      placement="fullScreen"
      closeModal={() => closeModal(false)}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      footer={
        <Button
          action="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          type="primary"
        >
          {isLoading ? 'Loading...' : 'Complete Check-Out'}
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!item}
              img={imageUrl}
              laneName={lane.display_name}
              Action={
                !!item && (
                  <Button
                    type="outlined"
                    startIcon={<SwapHorizOutlinedIcon />}
                    onClick={() =>
                      onSwapLane(item, LaneDirection.Arriving, true)
                    }
                  >
                    Move to Check-In
                  </Button>
                )
              }
            />
          }
        >
          <form>
            <GeneralFormSection />
            <CheckOutPowerUnit siteId={site_id} />
            <DriverDetailsFormSection readOnly />
            <CheckOutCargoAsset siteId={site_id} />
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CheckOutModal
