import { FC } from 'react'

import { Col } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'

interface IProps {
  siteId: string
}

const CargoAssetOnsite: FC<IProps> = (props) => {
  const { siteId } = props

  return (
    <CargoAssetFormSection>
      {({ isMinimized, isEmpty, isChassisVisible, showAccount }) => (
        <FormCol>
          {!isMinimized && (
            <>
              <CargoAssetFormSection.Type />

              <FormRow>
                <CargoAssetFormSection.OwnerId />
                <CargoAssetFormSection.AssetCarrier />
              </FormRow>
            </>
          )}

          <Col>
            <FormRow>
              <CargoAssetFormSection.LoadStatus />

              {!isEmpty && <CargoAssetFormSection.ShipmentNumber />}
            </FormRow>

            <CargoAssetFormSection.InspectionCompleted />
          </Col>

          {showAccount && <PowerUnitFormSection.Account siteId={siteId} />}

          {!isEmpty && (
            <Col items="stretch" gap={4}>
              <CargoAssetFormSection.SealNumbers />
              <CargoAssetFormSection.SealMatchesPW />
            </Col>
          )}

          {isChassisVisible && <CargoAssetFormSection.ChassisId />}

          {!isMinimized && (
            <FormRow>
              <CargoAssetFormSection.LicensePlateNumber />
              <CargoAssetFormSection.LicensePlateState />
            </FormRow>
          )}
        </FormCol>
      )}
    </CargoAssetFormSection>
  )
}

export default CargoAssetOnsite
