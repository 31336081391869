import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import { ISelectOption } from '@/types/interfaces/ui'
import {
  appointmentTypesSelectOptions,
  carriersSelectOptions,
  loadStatusSelectOptions
} from '@/utils/mappers'

const cargoAssetFilters = (accounts: ISelectOption[]): IFilterItem[] => [
  {
    id: 'appointmentType',
    name: 'Appointment Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: appointmentTypesSelectOptions
  },
  {
    id: 'assetCarrier',
    name: 'Asset Carrier',
    type: TableFilterType.List,
    placeholder: 'Search Carrier',
    options: carriersSelectOptions
  },
  {
    id: 'operatingCarrier',
    name: 'Operating Carrier',
    type: TableFilterType.List,
    placeholder: 'Search Carrier',
    options: carriersSelectOptions
  },
  {
    id: 'account',
    name: 'Account',
    type: TableFilterType.List,
    placeholder: 'Search Account',
    options: accounts
  },
  {
    id: 'arrivingLoadStatus',
    name: 'Load Status',
    type: TableFilterType.List,
    placeholder: 'Search Status',
    options: loadStatusSelectOptions
  },
  {
    id: 'assetDwell',
    name: 'Dwell',
    type: TableFilterType.Time
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  }
]

export default cargoAssetFilters
