import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Popover } from '@mui/material'
import clsx from 'clsx'
import React, { FC, useRef, useState } from 'react'

import { Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IAction } from '@/types/interfaces/table'

import styles from './ActionsDropdown.module.scss'

interface IProps {
  table?: boolean
  actions: IAction[]
  row?: any
  onClick: (action: string) => void
}

const ActionsDropdown: FC<IProps> = (props) => {
  const { row, actions, onClick, table = true } = props

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  return (
    <div
      className={clsx(styles.actions, table && styles.table)}
      onClick={(e) => e.stopPropagation()}
    >
      <Row
        tabIndex={0}
        ref={containerRef}
        items="center"
        justify="end"
        clickable
        onClick={(e) => {
          e.stopPropagation()
          toggle()
        }}
        className={styles.actionBtn}
      >
        <MoreHorizIcon />
      </Row>

      <Popover
        disablePortal
        id="actions-popover"
        open={open}
        anchorEl={containerRef.current}
        onClose={toggle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        classes={{
          paper: clsx(styles.actionsDropdown, table && styles.table)
        }}
      >
        <div>
          {actions.map((action) => (
            <Row
              id={action.id}
              key={action.id}
              tabIndex={0}
              items="center"
              gap={table ? 4 : 8}
              className={clsx(
                styles.option,
                table && styles.table,
                action.className
              )}
              onClick={(e) => {
                e.stopPropagation()
                onClick(action.id)
                setOpen(false)
              }}
            >
              {action.icon}

              <Text type={TextTypes.TEXT_MD} color={Color.gray800}>
                {typeof action.label === 'function'
                  ? action.label(row)
                  : action.label}
              </Text>
            </Row>
          ))}
        </div>
      </Popover>
    </div>
  )
}

export default ActionsDropdown
