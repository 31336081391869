import { VariantType } from 'notistack'
import { useCallback } from 'react'

import { showSnackbar } from '@/features/snackbars-queue/store'
import { SnackbarStoreItem } from '@/features/snackbars-queue/store/types'
import { useDispatch } from '@/store'

type Options = SnackbarStoreItem['options']

const useSnackbar = () => {
  const dispatch = useDispatch()

  const createSnackbarHandler = useCallback(
    (variant: VariantType) => (message: string, options?: Options) =>
      dispatch(showSnackbar({ message, options: { variant, ...options } })),
    [dispatch]
  )

  return {
    showDefaultSnackbar: createSnackbarHandler('default'),
    showSuccessSnackbar: createSnackbarHandler('success'),
    showErrorSnackbar: createSnackbarHandler('error'),
    showWarningSnackbar: createSnackbarHandler('warning'),
    showInfoSnackbar: createSnackbarHandler('info')
  }
}

export default useSnackbar
