import { ISRRecord } from '@/features/isr/types'
import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  emissionTypesFriendlyNames,
  fuelTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import {
  arrayToString,
  sealMatchesToBoolean,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'

const groupTitleMapper: { [key: string]: string } = {
  emissionType: 'Emission Type'
}

const isrColumns = (groupBy: string | undefined): ITableColumn[] => [
  ...(groupBy
    ? [
        {
          id: 'title',
          title: groupTitleMapper[groupBy] || 'Group',
          type: TableColumnType.Text,
          className: 'color-green500',
          sortable: true
        }
      ]
    : []),

  // TRANSACTION
  {
    id: 'inbound_event_time',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'outbound_event_time',
    title: 'Check-Out Time',
    type: TableColumnType.Date,
    sortable: true
  },

  // POWER UNIT
  {
    id: 'inbound_metadata.power_unit_owner_id',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'power_unit_license_plate_number',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'power_unit_license_plate_state',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'inbound_metadata.power_unit_carrier_usdot',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'inbound_metadata.power_unit_vin',
    title: 'VIN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'inbound_metadata.power_unit_weight_class',
    title: 'Class',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: weightClassesFriendlyNames,
    sortable: true
  },
  {
    id: 'inbound_metadata.power_unit_fuel_type',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: fuelTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'inbound_metadata.power_unit_emission_type',
    title: 'Emission',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: emissionTypesFriendlyNames,
    sortable: true
  },
  {
    id: 'inbound_metadata.account_name',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },

  // DRIVER DETAILS
  {
    id: 'driver_name',
    title: 'Name',
    type: TableColumnType.Text,
    sortable: true,
    converter: (row: ISRRecord) =>
      `${row.inbound_metadata.driver_first_name} ${row.inbound_metadata.driver_last_name}`
  },
  {
    id: 'inbound_metadata.driver_license_number',
    title: 'License',
    type: TableColumnType.Text,
    sortable: true
  },

  // CHECK-IN CARGO ASSET
  {
    id: 'inbound_metadata.cargo_asset_owner_id',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'inbound_metadata.cargo_asset_license_plate_number',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'inbound_metadata.cargo_asset_license_plate_state',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'inbound_metadata.shipment_number',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'inbound_metadata.seal_numbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },
  {
    id: 'inbound_metadata.seal_matchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true
  },

  // CHECK-OUT CARGO ASSET
  {
    id: 'outbound_metadata.cargo_asset_owner_id',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'outbound_metadata.cargo_asset_license_plate_number',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'outbound_metadata.cargo_asset_license_plate_state',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'outbound_metadata.shipment_number',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'outbound_metadata.seal_numbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },
  {
    id: 'outbound_metadata.seal_matchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true
  },
  {
    id: 'inbound_event_user',
    title: 'Source',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'outbound_metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  }
]

export default isrColumns
