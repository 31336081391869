import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { FC } from 'react'

import { Avatar, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './PortalDropdownItem.module.scss'

interface IProps {
  type: PortalTypes
  text: string
}

const PortalDropdownItem: FC<IProps> = (props) => {
  const { type, text } = props

  const isEnterprise = type === PortalTypes.Enterprise

  return (
    <Row items="center" gap={4} className={styles.itemWrapper}>
      <Avatar
        rectangle
        size="md"
        name={text}
        color={isEnterprise ? Color.purple400 : Color.blue300}
        textColor={Color.gray0}
        img={
          isEnterprise && (
            <BusinessOutlinedIcon className={styles.enterpriseSvg} />
          )
        }
      />

      <Text
        type={TextTypes.TEXT_SM}
        color={Color.gray700}
        weight={FontWeight.SEMIBOLD}
        className="!tw-leading-[16px]"
      >
        {text}
      </Text>
    </Row>
  )
}

export default PortalDropdownItem
