import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { InputLabel } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ReactComponent as BobtailTruck } from '@/assets/icons/bobtail_truck.svg'
import { ReactComponent as DropTruck } from '@/assets/icons/drop_truck.svg'
import { Col, Row, Text } from '@/components/atoms'
import classes from '@/components/atoms/Input/classes'
import { FORM_IDS } from '@/features/forms/constants'
import { triggerValidationIfError } from '@/features/forms/utils'
import { Color } from '@/styles/palette'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { appointmentTypesFriendlyNames } from '@/utils/mappers'

import styles from './AppointmentTypeSelector.module.scss'

const AppointmentTypeSelector = () => {
  const {
    watch,
    setValue,
    formState: { errors },
    trigger
  } = useFormContext()

  const { inputLabelClasses } = classes

  const items = [
    { id: AppointmentTypes.DROP, icon: <DropTruck /> },
    { id: AppointmentTypes.LIVE, icon: <LocalShippingOutlinedIcon /> },
    { id: AppointmentTypes.Bobtail, icon: <BobtailTruck /> },
    { id: AppointmentTypes.Visitor, icon: <AirportShuttleOutlinedIcon /> }
  ]

  const value = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)

  const onChange = (newValue: AppointmentTypes) => {
    setValue(FORM_IDS.GENERAL.APPOINTMENT_TYPE, newValue)

    triggerValidationIfError(
      [FORM_IDS.POWER_UNIT.USDOT, FORM_IDS.POWER_UNIT.CARRIER],
      errors,
      trigger
    )
  }

  return (
    <Col>
      <InputLabel
        required
        focused
        shrink
        variant="standard"
        classes={inputLabelClasses}
      >
        Appointment Type
      </InputLabel>

      <div className={styles.items}>
        {items.map((item) => (
          <Row
            gap={4}
            key={item.id}
            items="center"
            tabIndex={0}
            onClick={() => onChange(item.id)}
            onKeyDown={(e) => e.key === 'Enter' && onChange(item.id)}
            className={clsx(styles.item, item.id === value && styles.selected)}
          >
            {item.icon}

            <Text
              color={Color.gray700}
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
            >
              {appointmentTypesFriendlyNames[item.id]}
            </Text>
          </Row>
        ))}
      </div>
    </Col>
  )
}

export default AppointmentTypeSelector
