import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import clsx from 'clsx'
import { closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack'
import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'

import { Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

import styles from './Snackbar.module.scss'

const iconMapper = {
  default: undefined,
  success: <CheckCircleOutlineIcon />,
  error: <ErrorOutlineIcon />,
  warning: <ErrorOutlineIcon />,
  info: <InfoOutlinedIcon />
}

const Snackbar: ForwardRefRenderFunction<HTMLDivElement, CustomContentProps> = (
  props,
  ref
) => {
  const { id, action, message, variant, ...other } = props

  const handleClose = () => {
    closeSnackbar(id)
  }

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      {...other}
      className={styles.snackbarWrapper}
    >
      <div className={clsx(styles.snackbar, styles[variant])}>
        <Row items="center" gap={8} className={styles.snackbarContent}>
          {iconMapper[variant]}

          <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
            {message}
          </Text>
          {action as ReactNode}
        </Row>

        <div className={styles.closeIcon} onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
    </SnackbarContent>
  )
}

export default forwardRef(Snackbar)
