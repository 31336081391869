import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  BaseSnackbarPayload,
  SnackbarSliceState,
  UpdateStatusPayload
} from '@/features/snackbars-queue/store/types'
import { SnackbarStatus } from '@/features/snackbars-queue/enums'
import { generateRandomId } from '@/utils/helpers'

const initialState: SnackbarSliceState = {
  snackbarsQueue: []
}

const snackbarsStore = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<BaseSnackbarPayload>) => {
      const { payload } = action

      state.snackbarsQueue.push({
        ...payload,
        id: generateRandomId(),
        status: SnackbarStatus.Queued
      })
    },

    updateSnackbarStatus: (
      state,
      action: PayloadAction<UpdateStatusPayload>
    ) => {
      const { payload } = action
      const { id, status } = payload

      state.snackbarsQueue = state.snackbarsQueue.map((queueItem) => {
        return queueItem.id === id ? { ...queueItem, status } : queueItem
      })
    },

    hideSnackbar: (state, action: PayloadAction<string>) => {
      const { payload } = action

      state.snackbarsQueue = state.snackbarsQueue.filter(
        (queueItem) => queueItem.id !== payload
      )
    }
  }
})

export const snackbarStoreReducer = snackbarsStore.reducer
export const { showSnackbar, hideSnackbar, updateSnackbarStatus } =
  snackbarsStore.actions
