import { get } from 'lodash'

import { DomainTransactionTypes } from '@/features/gate/enums'
import { transactionTypesFriendlyNames } from '@/utils/mappers'

const transactionTypeToDirection = (row: any, id: string) => {
  const value = get(row, id) as DomainTransactionTypes

  return value ? transactionTypesFriendlyNames[value] : ''
}

export default transactionTypeToDirection
