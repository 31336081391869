import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { getInitials } from '@/utils/helpers'

import styles from './Avatar.module.scss'

interface IProps {
  size: 'sm' | 'md' | 'lg' | 'xl'
  name: string
  img?: string | ReactNode
  color?: Color
  textColor?: Color
  rectangle?: boolean
}

const Avatar: FC<IProps> = (props) => {
  const { size, img, name, color, textColor, rectangle } = props

  const type = {
    sm: TextTypes.TEXT_XS,
    md: TextTypes.TEXT_XS,
    lg: TextTypes.TEXT_MD,
    xl: TextTypes.TEXT_XS
  }

  const weight = {
    sm: FontWeight.SEMIBOLD,
    md: FontWeight.BOLD,
    lg: FontWeight.SEMIBOLD,
    xl: FontWeight.BOLD
  }

  const Image =
    typeof img === 'string' ? (
      <img src={img} alt={name} className={styles.avatarImg} />
    ) : (
      img
    )

  return (
    <div
      className={clsx(
        styles.avatar,
        styles[size],
        color && `background-${color}`,
        textColor && textColor,
        rectangle && styles.rectangle
      )}
    >
      {img ? (
        Image
      ) : (
        <Text
          weight={weight[size]}
          type={type[size]}
          align={TextAlign.CENTER}
          color={Color.gray0}
        >
          {getInitials(name)}
        </Text>
      )}
    </div>
  )
}

export default Avatar
