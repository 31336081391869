import * as yup from 'yup'

import { cargoAssetValidations } from '@/features/forms/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

export const CheckOutSchema = yup.object({
  // General Section
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  // Power Unit Section
  power_unit_owner_id: yup.string().max(50, 'Max length is 50'),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes)),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string(),
  power_unit_carrier_name: yup.string(),
  power_unit_carrier_usdot: yup.string(),
  power_unit_vin: yup.string(),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses)),
  power_unit_fuel_type: yup.mixed<FuelTypes>().oneOf(Object.values(FuelTypes)),
  account_name: yup.string(),

  // Driver Section
  driver_first_name: yup.string(),
  driver_last_name: yup.string(),
  driver_license_number: yup.string(),
  driver_phone_number: yup.string(),
  driver_license_state: yup.string(),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) => schema.oneOf(Object.values(CargoAssetTypes)),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup
    .string()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) => schema.required('ID is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_carrier_name: yup.string(),
  chassis_id: yup.string().when(cargoAssetValidations.isChassisVisible.keys, {
    is: cargoAssetValidations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(cargoAssetValidations.isNotMinimized.keys, {
      is: cargoAssetValidations.isNotMinimized.validate,
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  load_status: yup.string().when(cargoAssetValidations.isVisible.keys, {
    is: cargoAssetValidations.isVisible.validate,
    then: (schema) => schema.required('Load Status is required'),
    otherwise: (schema) => schema
  }),
  shipment_number: yup.string().when(cargoAssetValidations.isNotEmpty.keys, {
    is: cargoAssetValidations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(cargoAssetValidations.isNotEmpty.keys, {
    is: cargoAssetValidations.isNotEmpty.validate,
    then: (schema) => schema.of(yup.string().max(50, 'Max length is 50')),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired()
})

export type CheckOutSchemaType = yup.InferType<typeof CheckOutSchema>
