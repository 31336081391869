import React, { forwardRef, ForwardRefRenderFunction } from 'react'

import DateInput from '@/components/atoms/Input/DateInput/DateInput'
import MultipleValuesInput from '@/components/atoms/Input/MultipleValuesInput/MultipleValuesInput'
import PhoneInput from '@/components/atoms/Input/PhoneInput/PhoneInput'
import TextInput from '@/components/atoms/Input/TextInput/TextInput'
import TimeInput from '@/components/atoms/Input/TimeInput/TimeInput'
import {
  DateInputProps,
  MultipleValuesInputProps,
  PhoneInputProps,
  TextInputProps,
  TimeInputProps
} from '@/types/interfaces/ui'

type IProps =
  | TextInputProps
  | PhoneInputProps
  | DateInputProps
  | TimeInputProps
  | MultipleValuesInputProps

const CustomInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  props,
  ref
) => {
  const { type = 'text', variant = 'standard', ...restProps } = props

  if (type === 'phone') {
    return (
      <PhoneInput
        {...(restProps as PhoneInputProps)}
        variant={variant}
        ref={ref}
      />
    )
  }

  if (type === 'date') {
    return (
      <DateInput
        {...(restProps as DateInputProps)}
        variant={variant}
        ref={ref}
      />
    )
  }

  if (type === 'time') {
    return (
      <TimeInput
        {...(restProps as TimeInputProps)}
        variant={variant}
        ref={ref}
      />
    )
  }

  if (type === 'multiple') {
    return (
      <MultipleValuesInput
        {...(restProps as MultipleValuesInputProps)}
        ref={ref}
      />
    )
  }

  return (
    <TextInput {...(props as TextInputProps)} variant={variant} ref={ref} />
  )
}

export default forwardRef(CustomInput)
