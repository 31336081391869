import { FC } from 'react'

import {
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'

interface IProps {
  siteId: string
}

const IsrReportPowerUnit: FC<IProps> = (props) => {
  const { siteId } = props

  const allowApiCalls = (isDirty: boolean) => isDirty

  return (
    <PowerUnitFormSection>
      <FormCol>
        <FormRow>
          <PowerUnitFormSection.OwnerId />
          <PowerUnitFormSection.UsDOT />
        </FormRow>

        <FormRow>
          <PowerUnitFormSection.LicensePlateNumber />
          <PowerUnitFormSection.LicensePlateState />
        </FormRow>

        <FormRow>
          <PowerUnitFormSection.VinNumber allowApiCalls={allowApiCalls} />
          <PowerUnitFormSection.WeightClass />
        </FormRow>

        <FormRow>
          <PowerUnitFormSection.FuelType />
          <PowerUnitFormSection.Account siteId={siteId} />
        </FormRow>
      </FormCol>
    </PowerUnitFormSection>
  )
}

export default IsrReportPowerUnit
