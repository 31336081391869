import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import {
  appointmentTypesSelectOptions,
  carriersSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions
} from '@/utils/mappers'

const driversFilters: IFilterItem[] = [
  {
    id: 'appointmentType',
    name: 'Appointment Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: appointmentTypesSelectOptions
  },
  {
    id: 'puClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    options: classListSelectOptions
  },
  {
    id: 'puDwell',
    name: 'Dwell',
    type: TableFilterType.Time
  },
  {
    id: 'puFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    options: fuelTypeSelectOptions
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  },
  {
    id: 'operatingCarrier',
    name: 'Operating Carrier',
    type: TableFilterType.List,
    placeholder: 'Search Carrier',
    options: carriersSelectOptions
  }
]

export default driversFilters
