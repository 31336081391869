import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ReadOnlyFormValue, Select } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { PowerUnitTypes } from '@/types/enums/transactionDetails'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import {
  powerUnitTypeSelectOptions,
  powerUnitTypesFriendlyNames
} from '@/utils/mappers'

const Type: FC<ReadOnlyProps> = (props) => {
  const { readOnly, customValue, label = 'Type' } = props
  const { control, watch } = useFormContext()

  const powerUnitType = (customValue ||
    watch(FORM_IDS.POWER_UNIT.TYPE)) as PowerUnitTypes

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={!!powerUnitType && powerUnitTypesFriendlyNames[powerUnitType]}
      />
    )
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.TYPE}
      control={control}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          required
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          options={powerUnitTypeSelectOptions}
        />
      )}
    />
  )
}

export default Type
