import {
  ISiteRoles,
  ISiteRolesResponseData
} from '@/features/user-management/api/types'
import { Permissions, SiteRoles } from '@/types/enums/global'

const convertRolesToAPIFormat = (
  siteRoles: ISiteRoles[]
): ISiteRolesResponseData[] =>
  siteRoles.map((site) => {
    const { site_id, roles } = site

    return {
      site_id,
      roles: roles.map((item) => {
        const { role, access } = item

        if (role === SiteRoles.ISRManager && access === Permissions.Read) {
          return SiteRoles.ISRViewer
        }

        if (role === SiteRoles.Gate && access === Permissions.Read) {
          return SiteRoles.GateViewer
        }

        return role as SiteRoles
      })
    }
  })

export default convertRolesToAPIFormat
