import { Permissions, SiteRoles } from '@/types/enums/global'

const convertRoleToUIFormat = (
  role: SiteRoles
): { role: SiteRoles; access: Permissions } => {
  if (role === SiteRoles.ISRViewer) {
    return {
      role: SiteRoles.ISRManager,
      access: Permissions.Read
    }
  }

  if (role === SiteRoles.GateViewer) {
    return {
      role: SiteRoles.Gate,
      access: Permissions.Read
    }
  }

  return {
    role,
    access: Permissions.Full
  }
}

export default convertRoleToUIFormat
