import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { Collapse, Fade } from '@mui/material'
import clsx from 'clsx'
import {
  FC,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Row, Text, Tooltip } from '@/components/atoms'
import { CollapsedNavigationPopover } from '@/components/organisms/Navigation/components'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { INavigationItem, INavigationSubItem } from '@/types/interfaces/ui'

import styles from './NavigationItem.module.scss'

interface IProps {
  item: INavigationItem
  sidebarCollapsed: boolean
  isExpanded: boolean
  onExpand: (id: string) => void
}

const NavigationItem: FC<IProps> = (props) => {
  const { item, isExpanded, onExpand, sidebarCollapsed } = props

  const location = useLocation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const containsSubItems = Array.isArray(item.items)
  const showSubItems = isExpanded && !sidebarCollapsed

  const parentItemActive = item.link === location.pathname
  const childItemActive = useMemo(() => {
    if (parentItemActive) return false

    // When doesn't include sub-items or the navigation bar is not collapsed
    if (!Array.isArray(item.items)) return false

    const links = item.items.map((subItem) => subItem.link)

    return links.includes(location.pathname)
  }, [parentItemActive, location.pathname, sidebarCollapsed])

  const showParentBg = parentItemActive || (childItemActive && sidebarCollapsed)
  const boldText = parentItemActive || childItemActive

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (item.link) return

    if (sidebarCollapsed) {
      setAnchorEl(e.currentTarget)
      return
    }

    if (containsSubItems) {
      onExpand(item.id)
    }
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClick({
        currentTarget: event.currentTarget
      } as MouseEvent<HTMLElement>)
    }
  }

  // Hide popup if sidebar is not minimized
  useEffect(() => {
    if (!sidebarCollapsed) {
      setAnchorEl(null)
    }
  }, [sidebarCollapsed])

  const renderParentItem = () => {
    const ParentItem = item.link ? Link : Row

    return (
      <ParentItem
        tabIndex={0}
        key={item.id}
        to={item.link as string}
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        className={clsx(styles.navItem, showParentBg && styles.active)}
      >
        {item.icon}

        <Fade in={!sidebarCollapsed} timeout={500} appear={false}>
          <div>
            <Text
              type={TextTypes.TEXT_SM}
              weight={boldText ? FontWeight.BOLD : FontWeight.REGULAR}
              color={Color.gray300}
            >
              {item.title}
            </Text>

            {containsSubItems && (
              <div className={clsx(styles.arrow, isExpanded && styles.opened)}>
                <KeyboardArrowDownOutlinedIcon />
              </div>
            )}
          </div>
        </Fade>
      </ParentItem>
    )
  }

  const renderSubItem = (subItem: INavigationSubItem) => {
    const isActive = location.pathname === subItem.link

    return (
      <Fade in={showSubItems} timeout={500} key={subItem.id} appear={false}>
        <Link
          to={subItem.link}
          className={clsx(
            styles.navItem,
            styles.subItem,
            isActive && styles.active
          )}
        >
          <Text
            type={TextTypes.TEXT_SM}
            weight={isActive ? FontWeight.BOLD : FontWeight.REGULAR}
            color={Color.gray300}
          >
            {subItem.title}
          </Text>
        </Link>
      </Fade>
    )
  }

  return (
    <div className={styles.navItemWrapper}>
      <Tooltip show={sidebarCollapsed} placement="right" tooltip={item.title}>
        <div>{renderParentItem()}</div>
      </Tooltip>

      {containsSubItems && (
        <Collapse timeout={600} in={showSubItems} unmountOnExit>
          <div>{item.items?.map(renderSubItem)}</div>
        </Collapse>
      )}

      {/* A popup which can be open by clicking on menu items which contains
      sub items when navigation is collapsed */}
      {sidebarCollapsed && containsSubItems && (
        <CollapsedNavigationPopover
          name={`popover-${item.id}`}
          anchorEl={anchorEl}
          title={item.title}
          Icon={item.icon}
          onClose={() => setAnchorEl(null)}
          items={item.items as INavigationSubItem[]}
        />
      )}
    </div>
  )
}

export default NavigationItem
