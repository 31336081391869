import MenuIcon from '@mui/icons-material/Menu'
import clsx from 'clsx'
import { FC, KeyboardEvent, PropsWithChildren } from 'react'

import { Row, Text } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './WithHeaderTemplate.module.scss'

interface IProps {
  title: string
  className?: string
}

const WithHeaderTemplate: FC<IProps & PropsWithChildren> = (props) => {
  const { children, title, className } = props

  const { toggleMobileNavigation } = useMobileNavigationContext()

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      toggleMobileNavigation()
    }
  }

  return (
    <div className={styles.content}>
      <header className={styles.header}>
        <Row items="center" gap={8}>
          <div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={styles.burgerIcon}
            onClick={() => toggleMobileNavigation()}
          >
            <MenuIcon width={16} height={16} />
          </div>

          <Text
            type={TextTypes.TEXT_XL}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {title}
          </Text>
        </Row>

        <Row id="header-actions" items="center" gap={16} />
      </header>

      <div className={clsx(styles.innerContent, className)}>{children}</div>
    </div>
  )
}

export default WithHeaderTemplate
