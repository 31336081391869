export enum WeightClasses {
  Class2b7 = '2b-7',
  Class8 = '8'
}

export enum FuelTypes {
  Gas = 'G',
  Diesel = 'D',
  CNG = 'N',
  LNG = 'L',
  HFC = 'R',
  Electric = 'E'
}

export enum EmissionType {
  E = 'E',
  NZE = 'NZE',
  ZE = 'ZE'
}

export enum AppointmentTypes {
  LIVE = 'live',
  Visitor = 'visitor',
  DROP = 'drop',
  Bobtail = 'bobtail'
}

export enum LoadTypes {
  Empty = 'empty',
  Loaded = 'loaded',
  Partial = 'partially_loaded'
}

export enum CargoAssetTypes {
  Trailer = 'trailer',
  OceanContainer = 'ocean_container',
  IntermodalContainer = 'intermodal_container'
}

export enum PowerUnitTypes {
  Tractor = 'tractor',
  BoxTruck = 'box_truck',
  SprinterVan = 'sprinter_van',
  Vehicle = 'vehicle'
}
