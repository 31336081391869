import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from 'react'

interface MobileNavigationContextValue {
  toggleMobileNavigation: (value?: boolean) => void
  mobileNavigationOpened: boolean
}

const MobileNavigationContext = createContext<MobileNavigationContextValue>(
  {} as MobileNavigationContextValue
)

const MobileNavigationContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [mobileNavigationOpened, setMobileNavigationOpened] = useState(false)

  const toggleMobileNavigation = (value?: boolean) => {
    if (typeof value === 'boolean') {
      setMobileNavigationOpened(value)
      return
    }

    setMobileNavigationOpened(!mobileNavigationOpened)
  }

  const providerValue = useMemo(
    () => ({
      toggleMobileNavigation,
      mobileNavigationOpened
    }),
    [mobileNavigationOpened]
  )

  return (
    <MobileNavigationContext.Provider value={providerValue}>
      {children}
    </MobileNavigationContext.Provider>
  )
}

const useMobileNavigationContext = () => useContext(MobileNavigationContext)

export { MobileNavigationContextProvider, useMobileNavigationContext }
