import { FC, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'

import { Autocomplete, Input, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useCargoAssetFormSectionContext } from '@/features/forms/contexts'
import { prepareFieldID } from '@/features/forms/utils'
import { useGetCargoAssetsOnsiteQuery } from '@/features/gate/api'
import { CargoAsset } from '@/features/yard/types'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IProps extends ReadOnlyProps {
  required?: boolean
}

interface IAutocompleteProps {
  siteId: string
  autoComplete: boolean
  selectedEventId: string | undefined
  onOptionSelect: (value: CargoAsset | undefined) => void
}

const OwnerId: FC<IProps | IAutocompleteProps> = (props) => {
  const {
    readOnly,
    customValue,
    label = 'Asset ID',
    required = true
  } = props as IProps

  const { autoComplete, siteId, onOptionSelect, selectedEventId } =
    props as IAutocompleteProps

  const {
    control,
    watch,
    formState: { dirtyFields }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { fieldPrefix } = useCargoAssetFormSectionContext()

  const fieldId = prepareFieldID(FORM_IDS.CARGO_ASSET.OWNER_ID, fieldPrefix)
  const assetIdDebounced = useDebounce(watch(fieldId), DEBOUNCE_TIME)

  const { data: assetsById, isFetching } = useGetCargoAssetsOnsiteQuery(
    {
      site_id: siteId,
      org_id: orgId,
      type: 'id',
      cargo_asset_owner_id_prefix: assetIdDebounced
    },
    { skip: !autoComplete }
  )

  const getOptionLabel = useCallback(
    (option: CargoAsset) => option?.metadata?.cargo_asset_owner_id || '',
    []
  )

  const isOptionSelected = useCallback(
    (option: CargoAsset) => option.id === selectedEventId,
    [selectedEventId]
  )

  // On initial form load, if the Owner ID is already detected by TVE
  // then select the option from the list based on its value
  // That should work only for autocomplete mode and before field was edited by user
  useEffect(() => {
    const isFieldTouched = dirtyFields[fieldId]

    if (!autoComplete || !assetIdDebounced || isFieldTouched) return

    const selectedEvent = assetsById?.find?.(
      (asset) => asset.metadata.cargo_asset_owner_id === assetIdDebounced
    )

    if (selectedEvent) {
      onOptionSelect(selectedEvent)
    }
  }, [assetsById])

  if (autoComplete) {
    return (
      <Controller
        name={fieldId}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            required={required}
            label={label}
            name={field.name}
            inputValue={field.value}
            options={assetsById || []}
            loading={isFetching}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => {
              field.onChange(newValue)
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionSelected}
            onChange={onOptionSelect}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />
    )
  }

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={customValue || assetIdDebounced}
      />
    )
  }

  return (
    <Controller
      control={control}
      name={fieldId}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          required={required}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default OwnerId
