import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip, Col, Text } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  DriverDetailsFormSection,
  FormSection
} from '@/features/forms/components'
import { useGetPresignedUrlQuery } from '@/features/gate/api'
import {
  DomainEventSchema,
  DomainEventTypes,
  LaneDirection
} from '@/features/gate/enums'
import { getSealMatchesPWValue } from '@/features/gate/utils'
import {
  IsrReportCargoAsset,
  IsrReportGeneralDetails,
  IsrReportPowerUnit,
  VisitDetailsImages
} from '@/features/isr'
import { useUpdateISRRecordMutation } from '@/features/isr/api'
import {
  UpdateISRRecordRequestBody,
  UpdateISRRecordRequestParams
} from '@/features/isr/api/types'
import { ISRRecord } from '@/features/isr/types'
import { ISRVisitSchema, ISRVisitSchemaType } from '@/features/isr/utils'
import { useSnackbar } from '@/features/snackbars-queue'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { prepareSealNumbers, scrollToFirstFormError } from '@/utils/helpers'
import { emissionTypeByFuelMapper, sealMatchesMapper } from '@/utils/mappers'

import styles from './UpdateIsrReportModal.module.scss'

interface IProps extends IModalWithCloseFn {
  item: ISRRecord
}

const UpdateIsrReportModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { gate } = useStore((store) => store.gate)
  const { showErrorSnackbar } = useSnackbar()

  const {
    site_id,
    organization_id,
    outbound_correlation_id,
    inbound_correlation_id,
    inbound_event_id,
    outbound_event_id,
    outbound_metadata,
    inbound_metadata,
    power_unit_license_plate_number,
    power_unit_license_plate_state
  } = item
  const { mismatch } = outbound_metadata

  const [updateISRRecord, { isLoading }] = useUpdateISRRecordMutation()

  const { data: inboundImage } = useGetPresignedUrlQuery(
    {
      org_id: organization_id,
      site_id,
      event_id: inbound_event_id
    },
    { skip: !inbound_event_id }
  )
  const { data: outboundImage } = useGetPresignedUrlQuery(
    {
      org_id: organization_id,
      site_id,
      event_id: outbound_event_id
    },
    { skip: !outbound_event_id }
  )

  const formState = useForm<ISRVisitSchemaType>({
    resolver: yupResolver(ISRVisitSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,

    defaultValues: {
      power_unit_owner_id: inbound_metadata.power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot: inbound_metadata.power_unit_carrier_usdot,
      power_unit_vin: inbound_metadata.power_unit_vin,
      power_unit_weight_class: inbound_metadata.power_unit_weight_class,
      power_unit_fuel_type: inbound_metadata.power_unit_fuel_type,
      account_name: inbound_metadata.account_name?.[0],

      driver_first_name: inbound_metadata.driver_first_name,
      driver_last_name: inbound_metadata.driver_last_name,
      driver_license_number: inbound_metadata.driver_license_number,
      driver_phone_number: inbound_metadata.driver_phone_number,
      driver_license_state: inbound_metadata.driver_license_state,

      check_in_cargo_asset_owner_id: inbound_metadata.cargo_asset_owner_id,
      check_in_cargo_asset_license_plate_number:
        inbound_metadata.cargo_asset_license_plate_number,
      check_in_cargo_asset_license_plate_state:
        inbound_metadata.cargo_asset_license_plate_state,
      check_in_shipment_number: inbound_metadata.shipment_number,
      check_in_seal_numbers: inbound_metadata.seal_numbers,
      check_in_seal_matchPW: sealMatchesMapper[inbound_metadata.seal_matchPW],

      check_out_cargo_asset_owner_id: outbound_metadata.cargo_asset_owner_id,
      check_out_cargo_asset_license_plate_number:
        outbound_metadata.cargo_asset_license_plate_number,
      check_out_cargo_asset_license_plate_state:
        outbound_metadata.cargo_asset_license_plate_state,
      check_out_shipment_number: outbound_metadata.shipment_number,
      check_out_seal_numbers: outbound_metadata.seal_numbers,
      check_out_seal_matchPW: sealMatchesMapper[outbound_metadata.seal_matchPW]
    }
  })

  const {
    handleSubmit,
    formState: { submitCount, errors }
  } = formState

  const onSubmit = async (formData: ISRVisitSchemaType) => {
    if (!organization_id || !site_id) return

    if (!gate) {
      showErrorSnackbar('Gate not found')
      return
    }

    const commonMetadata = {
      power_unit_owner_id: formData.power_unit_owner_id || '',
      power_unit_license_plate_number: formData.power_unit_license_plate_number,
      power_unit_license_plate_state: formData.power_unit_license_plate_state,
      power_unit_carrier_usdot: formData.power_unit_carrier_usdot || '',
      power_unit_vin: formData.power_unit_vin || '',
      power_unit_weight_class: formData.power_unit_weight_class,
      power_unit_fuel_type: formData.power_unit_fuel_type,
      power_unit_emission_type:
        emissionTypeByFuelMapper[formData.power_unit_fuel_type],
      account_name: formData.account_name ? [formData.account_name] : [],

      driver_first_name: formData.driver_first_name,
      driver_last_name: formData.driver_last_name,
      driver_license_number: formData.driver_license_number,
      driver_phone_number: formData.driver_phone_number || '',
      driver_license_state: formData.driver_license_state
    }

    const params: UpdateISRRecordRequestParams = {
      org_id: organization_id,
      site_id,
      gate_id: gate.id,
      lane_id: inbound_metadata.lane_id
    }

    const body: UpdateISRRecordRequestBody = {
      license_plate_number: formData.power_unit_license_plate_number,
      license_plate_state: formData.power_unit_license_plate_state,
      records: [
        {
          correlation_id: inbound_correlation_id,
          event_type: DomainEventTypes.ManualISRRecordUpdate,
          schema: DomainEventSchema.September2024,
          metadata: {
            ...inbound_metadata,
            ...commonMetadata,

            cargo_asset_owner_id: formData.check_in_cargo_asset_owner_id || '',
            cargo_asset_license_plate_number:
              formData.check_in_cargo_asset_license_plate_number || '',
            cargo_asset_license_plate_state:
              formData.check_in_cargo_asset_license_plate_state || '',
            shipment_number: formData.check_in_shipment_number || '',
            seal_numbers: prepareSealNumbers(
              formData.check_in_seal_numbers as string[]
            ),
            seal_matchPW: getSealMatchesPWValue(
              formData.check_in_seal_matchPW,
              formData.check_in_seal_numbers as string[]
            )
          }
        },
        {
          correlation_id: outbound_correlation_id,
          event_type: DomainEventTypes.ManualISRRecordUpdate,
          schema: DomainEventSchema.September2024,
          metadata: {
            ...outbound_metadata,
            ...commonMetadata,

            cargo_asset_owner_id: formData.check_out_cargo_asset_owner_id || '',
            cargo_asset_license_plate_number:
              formData.check_out_cargo_asset_license_plate_number || '',
            cargo_asset_license_plate_state:
              formData.check_out_cargo_asset_license_plate_state || '',
            shipment_number: formData.check_out_shipment_number || '',
            seal_numbers: prepareSealNumbers(
              formData.check_out_seal_numbers as string[]
            ),
            seal_matchPW: getSealMatchesPWValue(
              formData.check_out_seal_matchPW,
              formData.check_out_seal_numbers as string[]
            )
          }
        }
      ]
    }

    const response = await updateISRRecord({
      params,
      body
    })

    if (!response.error) {
      closeModal()
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <>
      <Modal
        placement="fullScreen"
        title="Visit Details"
        closeModal={closeModal}
        bodyClassName={styles.modal}
        headerClassName="!tw-bg-transparent"
        header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
        footer={
          <Button
            type="primary"
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        }
        footerLeft={
          <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
            Changes will only be reflected on the ISR record.
          </Text>
        }
      >
        <TwoColumnModalWrapper
          leftSide={
            <VisitDetailsImages
              checkInImg={inboundImage}
              checkOutImg={outboundImage}
            />
          }
        >
          <FormProvider {...formState}>
            <form>
              <IsrReportGeneralDetails item={item} />
              <IsrReportPowerUnit siteId={site_id} />
              <DriverDetailsFormSection />

              <FormSection title="Cargo Asset">
                <Col items="stretch" gap={40}>
                  <IsrReportCargoAsset type={LaneDirection.Arriving} />
                  <IsrReportCargoAsset type={LaneDirection.Departing} />
                </Col>
              </FormSection>
            </form>
          </FormProvider>
        </TwoColumnModalWrapper>
      </Modal>
    </>
  )
}

export default UpdateIsrReportModal
