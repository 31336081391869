import { DomainTransactionTypes } from '@/features/gate/enums'
import {
  AppointmentTypes,
  CargoAssetTypes,
  EmissionType,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

export const fuelTypesFriendlyNames: { [key in FuelTypes]: string } = {
  [FuelTypes.Gas]: 'Gas',
  [FuelTypes.Diesel]: 'Diesel',
  [FuelTypes.HFC]: 'HFC',
  [FuelTypes.CNG]: 'CNG',
  [FuelTypes.LNG]: 'LNG',
  [FuelTypes.Electric]: 'Electric'
}

export const emissionTypesFriendlyNames: { [key in EmissionType]: string } = {
  [EmissionType.E]: 'Emission',
  [EmissionType.NZE]: 'Near Zero Emission',
  [EmissionType.ZE]: 'Zero Emission'
}

export const weightClassesFriendlyNames: { [key in WeightClasses]: string } = {
  [WeightClasses.Class2b7]: 'Class 2b-7',
  [WeightClasses.Class8]: 'Class 8'
}

export const appointmentTypesFriendlyNames: {
  [key in AppointmentTypes]: string
} = {
  [AppointmentTypes.LIVE]: 'LIVE',
  [AppointmentTypes.Visitor]: 'Visitor',
  [AppointmentTypes.DROP]: 'DROP',
  [AppointmentTypes.Bobtail]: 'Bobtail'
}

export const loadTypesFriendlyNames: { [key in LoadTypes]: string } = {
  [LoadTypes.Empty]: 'Empty',
  [LoadTypes.Loaded]: 'Loaded',
  [LoadTypes.Partial]: 'Partial'
}

export const cargoAssetTypesFriendlyNames: {
  [key in CargoAssetTypes]: string
} = {
  [CargoAssetTypes.Trailer]: 'Trailer',
  [CargoAssetTypes.OceanContainer]: 'Ocean Container',
  [CargoAssetTypes.IntermodalContainer]: 'Intermodal Container'
}

export const powerUnitTypesFriendlyNames: { [key in PowerUnitTypes]: string } =
  {
    [PowerUnitTypes.Tractor]: 'Tractor',
    [PowerUnitTypes.BoxTruck]: 'Box Truck',
    [PowerUnitTypes.SprinterVan]: 'Sprinter Van',
    [PowerUnitTypes.Vehicle]: 'Vehicle'
  }

export const transactionTypesFriendlyNames: {
  [key in DomainTransactionTypes]: string
} = {
  [DomainTransactionTypes.CheckIn]: 'Check-In',
  [DomainTransactionTypes.CheckOut]: 'Check-Out'
}
