import { get } from 'lodash'

import { statesOptions } from '@/utils/data'

const userFriendlyState = (row: any, id: string) => {
  const state = statesOptions.find((s) => s.id === get(row, id))

  return state?.label || ''
}

export default userFriendlyState
