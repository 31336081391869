import React, { FC, ReactNode } from 'react'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextAlign, TextTypes } from '@/types/enums/ui'

interface IProps {
  title: string
  description: string | ReactNode
}

const TitleWithDescription: FC<IProps> = (props) => {
  const { title, description } = props

  return (
    <Col items="center" justify="center" gap={8} className="tw-flex-1">
      <Text
        type={TextTypes.TEXT_LG}
        align={TextAlign.CENTER}
        color={Color.gray700}
      >
        {title}
      </Text>

      <Text
        type={TextTypes.TEXT_MD}
        align={TextAlign.CENTER}
        color={Color.gray600}
      >
        {description}
      </Text>
    </Col>
  )
}

export default TitleWithDescription
