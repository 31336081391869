import AddIcon from '@mui/icons-material/Add'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FormHelperText, MenuItem, Popover } from '@mui/material'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import React, { FC, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Button,
  Checkbox,
  Col,
  ReadOnlyFormValue,
  Row,
  Select,
  Text
} from '@/components/atoms'
import classes from '@/components/atoms/Input/classes'
import { ISite, ISiteRoles } from '@/features/user-management/api/types'
import styles from '@/features/user-management/components/AddEditUserModal/AddEditUserModal.module.scss'
import { EditUserSchemaType } from '@/features/user-management/utils'
import { Color } from '@/styles/palette'
import { Permissions, SiteRoles } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import {
  getSiteRolesSelectOptions,
  permissionsSelectOptions
} from '@/utils/data'

interface IProps {
  disabled: boolean
  sites: ISite[]
}

const PortalsAndRolesFormSection: FC<IProps> = (props) => {
  const { sites, disabled } = props

  const {
    watch,
    setValue,
    trigger,
    clearErrors,
    control,
    formState: { errors }
  } = useFormContext<EditUserSchemaType>()

  const [open, setOpen] = useState(false)
  const addSiteWrapperRef = useRef<HTMLDivElement | null>(null)

  const enterpriseEnabled = watch('enterpriseEnabled')
  const siteEnabled = watch('siteEnabled')

  const siteRoles = (watch('site_roles') || []) as ISiteRoles[]

  const addSite = (site: ISite) => {
    setOpen(false)

    setValue('site_roles', [
      ...siteRoles,
      {
        site_id: site.id,
        display_name: site.display_name,
        roles: [
          {
            role: undefined,
            access: undefined
          }
        ]
      }
    ])

    if (Object.keys(errors).includes('site_roles')) {
      clearErrors('site_roles')
    }
  }

  const togglePortalEnabled = (type: 'enterpriseEnabled' | 'siteEnabled') => {
    setValue(type, !watch(type))

    if (Object.keys(errors).includes('isAnyRoleEnabled')) {
      trigger('isAnyRoleEnabled')
    }

    if (Object.keys(errors).includes('site_roles')) {
      trigger('site_roles')
    }
  }

  const removeSite = (id: string) => {
    const newSites = siteRoles.filter((item) => item.site_id !== id)

    setValue('site_roles', newSites)
  }

  const removeRoleFromSite = (siteIndex: number, roleIndex: number) => {
    const siteRolesClone = cloneDeep(siteRoles)

    if (siteRolesClone[siteIndex].roles.length === 1) {
      siteRolesClone.splice(siteIndex, 1)
      setValue('site_roles', siteRolesClone)

      return
    }

    siteRolesClone[siteIndex].roles.splice(roleIndex, 1)
    setValue('site_roles', siteRolesClone)
  }

  const updateSiteRolePermission = (
    siteIndex: number,
    roleIndex: number,
    permission: string
  ) => {
    const siteRolesClone = cloneDeep(siteRoles)

    siteRolesClone[siteIndex].roles[roleIndex].access =
      permission as Permissions
    setValue('site_roles', siteRolesClone)

    if (permission) {
      // @ts-ignore
      clearErrors(`site_roles[${siteIndex}].roles[${roleIndex}].access`)
    }
  }

  const addSiteRole = (siteIndex: number) => {
    const siteRolesClone = cloneDeep(siteRoles)

    siteRolesClone[siteIndex].roles = [
      ...siteRolesClone[siteIndex].roles,
      {
        role: undefined,
        access: undefined
      }
    ]
    setValue('site_roles', siteRolesClone)
  }

  const availableSitesToAdd = (site: ISite) =>
    siteRoles.every((item: ISiteRoles) => item.site_id !== site.id)

  return (
    <>
      <Col className="tw-mt-24 tw-mb-8">
        <Text
          type={TextTypes.TEXT_LG}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Roles
        </Text>

        {!!errors.isAnyRoleEnabled && (
          <Text>
            <FormHelperText
              error
              variant="standard"
              classes={classes.helperTextClasses}
            >
              {errors.isAnyRoleEnabled?.message}
            </FormHelperText>
          </Text>
        )}
      </Col>

      <Col
        gap={8}
        items="stretch"
        className={clsx(disabled && 'tw-pointer-events-none tw-opacity-60')}
      >
        <Checkbox
          disabled={disabled}
          value={enterpriseEnabled}
          label="Allow Enterprise Portal access"
          onChange={() => togglePortalEnabled('enterpriseEnabled')}
        />

        {enterpriseEnabled && (
          <Col className={styles.roleListContainer}>
            <ReadOnlyFormValue required title="Role" value="Administrator" />
          </Col>
        )}

        <Col>
          <Checkbox
            value={siteEnabled}
            disabled={disabled}
            label="Allow Site Portal access"
            onChange={() => togglePortalEnabled('siteEnabled')}
          />

          {!!errors.site_roles && (
            <Text className="tw-ml-30">
              <FormHelperText
                error
                variant="standard"
                classes={classes.helperTextClasses}
              >
                {errors.site_roles?.message}
              </FormHelperText>
            </Text>
          )}
        </Col>

        {siteEnabled && (
          <>
            {siteRoles.map((site, siteIndex) => (
              <Col
                key={site.site_id}
                className={styles.roleListContainer}
                gap={8}
              >
                <Row justify="between" items="center" gap={10}>
                  <Text
                    type={TextTypes.TEXT_MD}
                    weight={FontWeight.SEMIBOLD}
                    color={Color.gray700}
                  >
                    {site.display_name}
                  </Text>

                  <Button
                    disabled={disabled}
                    type="dangerText"
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => removeSite(site.site_id)}
                  >
                    Delete
                  </Button>
                </Row>

                {site.roles.map((role, roleIndex) => (
                  <Row gap={8} key={role.role}>
                    <Controller
                      // @ts-ignore
                      name={`site_roles[${siteIndex}].roles[${roleIndex}].role`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <Select
                          required
                          disabled={disabled}
                          label="Role"
                          value={field.value}
                          options={getSiteRolesSelectOptions(
                            role.role,
                            site.roles
                          )}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(value) => {
                            field.onChange(value)

                            if (value === SiteRoles.Admin) {
                              updateSiteRolePermission(
                                siteIndex,
                                roleIndex,
                                Permissions.Full
                              )
                            }
                          }}
                        />
                      )}
                    />

                    <Controller
                      // @ts-ignore
                      name={`site_roles[${siteIndex}].roles[${roleIndex}].access`}
                      control={control}
                      render={({ field, fieldState }) =>
                        // @ts-ignore
                        watch(
                          // @ts-ignore
                          `site_roles[${siteIndex}].roles[${roleIndex}].role`
                        ) === SiteRoles.Admin ? (
                          <ReadOnlyFormValue
                            biggerGap
                            required
                            title="Permission"
                            value="Full Access"
                          />
                        ) : (
                          <Select
                            required
                            label="Permission"
                            value={field.value}
                            disabled={disabled}
                            options={permissionsSelectOptions}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            onChange={field.onChange}
                          />
                        )
                      }
                    />

                    <div
                      tabIndex={0}
                      className={styles.deleteIcon}
                      onClick={() => removeRoleFromSite(siteIndex, roleIndex)}
                    >
                      <CloseOutlinedIcon />
                    </div>
                  </Row>
                ))}

                {site.roles.length < 3 && (
                  <Button
                    type="text"
                    disabled={disabled}
                    className="tw-self-start"
                    startIcon={<AddIcon />}
                    onClick={() => addSiteRole(siteIndex)}
                  >
                    Add Role
                  </Button>
                )}
              </Col>
            ))}

            {siteRoles.length < sites.length && (
              <div ref={addSiteWrapperRef} className="tw-ml-[28px]">
                <Button
                  type="outlined"
                  keepFocus={open}
                  disabled={disabled}
                  startIcon={<AddIcon />}
                  className="tw-self-start"
                  onClick={() => setOpen(true)}
                >
                  Add Site
                </Button>

                <Popover
                  disablePortal
                  id="group-by-popover"
                  open={open}
                  anchorEl={addSiteWrapperRef.current}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  classes={{ paper: styles.addSiteDropdown }}
                >
                  {sites.filter(availableSitesToAdd).map((site) => (
                    <MenuItem
                      key={site.id}
                      onClick={() => addSite(site)}
                      className={styles.listOption}
                    >
                      {site.display_name}
                    </MenuItem>
                  ))}
                </Popover>
              </div>
            )}
          </>
        )}
      </Col>
    </>
  )
}

export default PortalsAndRolesFormSection
