import { FC } from 'react'

import { Col } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow,
  SuggestedItems
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'

interface IProps {
  suggestedIds: string[]
}

const CheckInCargoAsset: FC<IProps> = (props) => {
  const { suggestedIds } = props

  return (
    <CargoAssetFormSection>
      {({ isMinimized, isChassisVisible, isEmpty }) => (
        <FormCol>
          {!isMinimized && (
            <>
              <CargoAssetFormSection.Type />

              <Col gap={7}>
                <CargoAssetFormSection.OwnerId />
                <SuggestedItems
                  fieldId={FORM_IDS.CARGO_ASSET.OWNER_ID}
                  suggestedItems={suggestedIds}
                />
              </Col>

              <CargoAssetFormSection.AssetCarrier />
            </>
          )}

          <Col>
            <FormRow>
              <CargoAssetFormSection.LoadStatus />
              {!isEmpty && <CargoAssetFormSection.ShipmentNumber />}
            </FormRow>

            <CargoAssetFormSection.InspectionCompleted />
          </Col>

          {isChassisVisible && <CargoAssetFormSection.ChassisId />}

          {!isEmpty && (
            <Col items="stretch" gap={4}>
              <CargoAssetFormSection.SealNumbers />
              <CargoAssetFormSection.SealMatchesPW />
            </Col>
          )}

          {!isMinimized && (
            <FormRow>
              <CargoAssetFormSection.LicensePlateNumber />
              <CargoAssetFormSection.LicensePlateState />
            </FormRow>
          )}
        </FormCol>
      )}
    </CargoAssetFormSection>
  )
}

export default CheckInCargoAsset
