import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FC, useState } from 'react'

import { Button, Col, Select, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { DELETE_REASONS } from '@/features/gate/constants'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

interface IProps {
  loading: boolean
  onDelete: (reason: string) => void
  closeModal: (removeFromMovedItems?: boolean) => void
}

const DeleteQueueItemModal: FC<IProps> = (props) => {
  const { onDelete, closeModal, loading } = props

  const [reason, setReason] = useState<string | undefined>()

  return (
    <Modal
      title="Delete Card"
      closeModal={() => closeModal(false)}
      placement="center"
      footer={
        <Button
          type="danger"
          disabled={!reason || loading}
          startIcon={<DeleteOutlineIcon />}
          onClick={() => onDelete(reason!)}
        >
          {loading ? 'Deleting...' : 'Delete Card'}
        </Button>
      }
    >
      <Col gap={8}>
        <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
          Are you sure you want to delete this card? This will remove them from
          the queue and cannot be undone.
        </Text>

        <Select
          required
          label="Select Reason"
          value={reason}
          options={DELETE_REASONS}
          onChange={(value) => setReason(value as string)}
        />
      </Col>
    </Modal>
  )
}

export default DeleteQueueItemModal
