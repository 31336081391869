import { FC } from 'react'

import {
  DriverDetailsFormSection,
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'
import { NameType } from '@/features/forms/enums'
import { CargoAsset } from '@/features/yard/types'

interface IProps {
  item: CargoAsset
}

const CargoAssetOnsitePowerUnit: FC<IProps> = (props) => {
  const { item } = props
  const { metadata } = item

  const {
    power_unit_owner_id,
    driver_first_name,
    driver_last_name,
    driver_license_number,
    driver_phone_number,
    driver_license_state,
    power_unit_carrier_name
  } = metadata

  return (
    <PowerUnitFormSection title="Power Unit and Driver Details">
      <FormCol>
        <PowerUnitFormSection.OwnerId
          readOnly
          customValue={power_unit_owner_id}
        />

        <DriverDetailsFormSection.Name
          readOnly
          type={NameType.Full}
          customValue={`${driver_first_name} ${driver_last_name}`}
        />

        <FormRow>
          <DriverDetailsFormSection.Id
            readOnly
            label="Driver License #"
            customValue={`${driver_license_number} (${driver_license_state})`}
          />
          <DriverDetailsFormSection.Phone
            readOnly
            label="Driver Phone #"
            customValue={driver_phone_number}
          />
        </FormRow>

        <PowerUnitFormSection.OperatingCarrier
          readOnly
          customValue={power_unit_carrier_name}
        />
      </FormCol>
    </PowerUnitFormSection>
  )
}

export default CargoAssetOnsitePowerUnit
