import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import {
  classListSelectOptions,
  emissionTypesSelectOptions,
  fuelTypeSelectOptions
} from '@/utils/mappers'

const isrFilters: IFilterItem[] = [
  {
    id: 'lastActivity',
    name: 'Date Range',
    type: TableFilterType.DateRange,
    fieldsToValidate: ['checkInTime', 'checkOutTime']
  },
  {
    id: 'puClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    options: classListSelectOptions
  },
  {
    id: 'puFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    options: fuelTypeSelectOptions
  },
  {
    id: 'emissionType',
    name: 'Fuel Emission Type',
    type: TableFilterType.List,
    placeholder: 'Search Emission Type',
    options: emissionTypesSelectOptions
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  }
]

export default isrFilters
