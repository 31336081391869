import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { ReadOnlyFormValue } from '@/components/atoms'
import {
  AppointmentTypeSelector,
  FormCol,
  FormRow,
  FormSection
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { DateFormat } from '@/types/enums/ui'
import { formatDate } from '@/utils/helpers'

interface IProps {
  title?: string
}

const GeneralFormSection: FC<IProps> = (props) => {
  const { title = 'General' } = props
  const { watch } = useFormContext()

  const date = watch(FORM_IDS.GENERAL.DATE)

  return (
    <FormSection title={title}>
      <FormCol>
        <FormRow>
          <ReadOnlyFormValue
            title="Date"
            value={formatDate(date, DateFormat.DATE)}
          />

          <ReadOnlyFormValue
            title="Time"
            value={formatDate(date, DateFormat.TIME)}
          />
        </FormRow>

        <AppointmentTypeSelector />
      </FormCol>
    </FormSection>
  )
}

export default GeneralFormSection
