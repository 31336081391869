export interface IInvite {
  id: string
  email: string
  status: string
  created_date: Date
  expiry_date: Date
}

export interface IUser {
  id: string
  name: string
  email: string
  first_name: string
  last_name: string
  last_login: string
  last_ip: string
  disabled: boolean
}

export enum UserManagementTab {
  Users = 'users',
  Invites = 'invites'
}
