import dayjs, { Dayjs } from 'dayjs'

import { DateFormat } from '@/types/enums/ui'

const formatDate = (
  date: Date | Dayjs | string | undefined,
  format: DateFormat,
  fallback = '-'
): string => {
  if (!date) return fallback

  const dayjsFormat = dayjs.isDayjs(date) ? date : dayjs(date)

  return dayjsFormat.format(format)
}

export default formatDate
