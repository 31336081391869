import { IUser } from '@/features/user-management/types'
import { TableActions, TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'

const userManagementColumns = (): ITableColumn[] => [
  {
    id: 'name',
    title: 'Name',
    type: TableColumnType.Text,
    className: 'color-green500',
    sortable: true
  },
  {
    id: 'email',
    title: 'Email',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'last_login',
    title: 'Last Login',
    type: TableColumnType.Date
  },
  {
    id: 'disabled',
    title: 'Status',
    type: TableColumnType.Chip,
    getLabel: (row: IUser) => (row.disabled ? 'Inactive' : 'Active'),
    getVariant: (row: IUser) => (row.disabled ? 'default' : 'success')
  },
  {
    id: 'actions',
    title: '',
    type: TableColumnType.Actions,
    className: 'tw-w-55',
    actions: [
      { id: TableActions.Edit, label: 'Edit' },
      {
        id: TableActions.Toggle,
        label: (row: IUser) => (row.disabled ? 'Reactivate' : 'Deactivate')
      },
      {
        id: TableActions.Delete,
        label: 'Delete',
        className: 'color-red500'
      }
    ]
  }
]

export default userManagementColumns
