import React from 'react'

import { FormCol, FormRow, FormSection } from '@/features/forms/components'
import { NameType } from '@/features/forms/enums'

import { Id, LicenseState, Name, Phone } from './fields'

interface IProps {
  readOnly?: boolean
  title?: string
}

const DriverDetailsFormSection = (props: IProps) => {
  const { readOnly, title = 'Driver Details' } = props

  return (
    <FormSection title={title}>
      <FormCol>
        <FormRow>
          <Name type={NameType.First} readOnly={readOnly} />
          <Name type={NameType.Last} readOnly={readOnly} />
        </FormRow>

        <FormRow>
          <Id readOnly={readOnly} />
          <LicenseState readOnly={readOnly} />
        </FormRow>

        <Phone readOnly={readOnly} />
      </FormCol>
    </FormSection>
  )
}

DriverDetailsFormSection.Id = Id
DriverDetailsFormSection.Name = Name
DriverDetailsFormSection.Phone = Phone
DriverDetailsFormSection.LicenseState = LicenseState

export default DriverDetailsFormSection
