import { baseApi, withParams } from '@/api'

import { HttpMethod } from '@/api/enums'
import {
  FetchISRRecordsRequest,
  FetchISRRecordsResponse,
  UpdateISRRecordRequest,
  UpdateISRRecordResponse
} from '@/features/isr/api/types'
import endpoints from './endpoints'

export const isrApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchISRRecords: builder.query<
      FetchISRRecordsResponse,
      FetchISRRecordsRequest
    >({
      providesTags: ['ISRRecord'],
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_ISR_RECORDS, { org_id, site_id }),
        params
      }),
      keepUnusedDataFor: 0
    }),

    updateISRRecord: builder.mutation<
      UpdateISRRecordResponse,
      UpdateISRRecordRequest
    >({
      invalidatesTags: ['ISRRecord'],
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.UPDATE_ISR_RECORD, params),
          method: HttpMethod.POST,
          body
        }
      }
    })
  })
})

export const { useFetchISRRecordsQuery, useUpdateISRRecordMutation } = isrApi
