import { MuiTelInput, MuiTelInputCountry, MuiTelInputInfo } from 'mui-tel-input'
import React, { forwardRef, ForwardRefRenderFunction } from 'react'

import { PhoneInputProps } from '@/types/interfaces/ui'

import classes from '../classes'

const ALLOWED_COUNTRIES: MuiTelInputCountry[] = ['US', 'MX', 'CA']
const DEFAULT_COUNTRY = 'US'

const PhoneInput: ForwardRefRenderFunction<
  HTMLInputElement,
  PhoneInputProps
> = (props, ref) => {
  const { readOnly, onChange, ...rest } = props

  const { inputClasses, rootClasses, inputLabelClasses, helperTextClasses } =
    classes

  const handleChange = (value: string, info: MuiTelInputInfo) => {
    if (!info.nationalNumber) {
      onChange?.('')
      return
    }

    onChange?.(value || '')
  }

  return (
    <MuiTelInput
      {...rest}
      forceCallingCode
      inputRef={ref}
      onChange={handleChange}
      onlyCountries={ALLOWED_COUNTRIES}
      defaultCountry={DEFAULT_COUNTRY}
      classes={rootClasses}
      InputLabelProps={{ classes: inputLabelClasses }}
      FormHelperTextProps={{ classes: helperTextClasses }}
      InputProps={{
        readOnly,
        classes: { ...inputClasses, adornedStart: undefined }
      }}
    />
  )
}

export default forwardRef(PhoneInput)
