import { SealMatchesPW } from '@/features/gate/enums'

const sealMatchesMapper = {
  [SealMatchesPW.NoPW]: undefined,
  [SealMatchesPW.NoSeal]: undefined,
  [SealMatchesPW.Yes]: true,
  [SealMatchesPW.No]: false
}

export default sealMatchesMapper
