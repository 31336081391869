import { Checkbox, FormControlLabel } from '@mui/material'
import clsx from 'clsx'
import { ChangeEvent, FC } from 'react'

import styles from './Checkbox.module.scss'

interface IProps {
  value: boolean | undefined | null
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
  disabled?: boolean
  indeterminate?: boolean
}

const CustomCheckbox: FC<IProps> = (props) => {
  const { value, onChange, label, indeterminate, disabled } = props

  const Element = (
    <Checkbox
      indeterminate={indeterminate}
      checked={value || false}
      onChange={onChange}
      disabled={disabled}
      classes={{
        root: styles.checkbox,
        checked: clsx(styles.checked, disabled && styles.disabled),
        indeterminate: styles.indeterminate
      }}
    />
  )

  return label ? (
    <FormControlLabel
      control={Element}
      label={label}
      classes={{ label: clsx(styles.label, disabled && styles.disabled) }}
    />
  ) : (
    Element
  )
}

export default CustomCheckbox
