import { ISelectOption } from '@/types/interfaces/ui'

const statesOptions: (ISelectOption & { longId: string })[] = [
  { id: 'AL', longId: 'ALABAMA', label: 'Alabama' },
  { id: 'AK', longId: 'ALASKA', label: 'Alaska' },
  { id: 'AZ', longId: 'ARIZONA', label: 'Arizona' },
  { id: 'AR', longId: 'ARKANSAS', label: 'Arkansas' },
  { id: 'CA', longId: 'CALIFORNIA', label: 'California' },
  { id: 'CO', longId: 'COLORADO', label: 'Colorado' },
  { id: 'CT', longId: 'CONNECTICUT', label: 'Connecticut' },
  { id: 'DE', longId: 'DELAWARE', label: 'Delaware' },
  { id: 'FL', longId: 'FLORIDA', label: 'Florida' },
  { id: 'GA', longId: 'GEORGIA', label: 'Georgia' },
  { id: 'HI', longId: 'HAWAII', label: 'Hawaii' },
  { id: 'ID', longId: 'IDAHO', label: 'Idaho' },
  { id: 'IL', longId: 'ILLINOIS', label: 'Illinois' },
  { id: 'IN', longId: 'INDIANA', label: 'Indiana' },
  { id: 'IA', longId: 'IOWA', label: 'Iowa' },
  { id: 'KS', longId: 'KANSAS', label: 'Kansas' },
  { id: 'KY', longId: 'KENTUCKY', label: 'Kentucky' },
  { id: 'LA', longId: 'LOUISIANA', label: 'Louisiana' },
  { id: 'ME', longId: 'MAINE', label: 'Maine' },
  { id: 'MD', longId: 'MARYLAND', label: 'Maryland' },
  { id: 'MA', longId: 'MASSACHUSETTS', label: 'Massachusetts' },
  { id: 'MI', longId: 'MICHIGAN', label: 'Michigan' },
  { id: 'MN', longId: 'MINNESOTA', label: 'Minnesota' },
  { id: 'MS', longId: 'MISSISSIPPI', label: 'Mississippi' },
  { id: 'MO', longId: 'MISSOURI', label: 'Missouri' },
  { id: 'MT', longId: 'MONTANA', label: 'Montana' },
  { id: 'NE', longId: 'NEBRASKA', label: 'Nebraska' },
  { id: 'NV', longId: 'NEVADA', label: 'Nevada' },
  { id: 'NH', longId: 'NEW_HAMPSHIRE', label: 'New Hampshire' },
  { id: 'NJ', longId: 'NEW_JERSEY', label: 'New Jersey' },
  { id: 'NM', longId: 'NEW_MEXICO', label: 'New Mexico' },
  { id: 'NY', longId: 'NEW_YORK', label: 'New York' },
  { id: 'NC', longId: 'NORTH_CAROLINA', label: 'North Carolina' },
  { id: 'ND', longId: 'NORTH_DAKOTA', label: 'North Dakota' },
  { id: 'OH', longId: 'OHIO', label: 'Ohio' },
  { id: 'OK', longId: 'OKLAHOMA', label: 'Oklahoma' },
  { id: 'OR', longId: 'OREGON', label: 'Oregon' },
  { id: 'PA', longId: 'PENNSYLVANIA', label: 'Pennsylvania' },
  { id: 'RI', longId: 'RHODE_ISLAND', label: 'Rhode Island' },
  { id: 'SC', longId: 'SOUTH_CAROLINA', label: 'South Carolina' },
  { id: 'SD', longId: 'SOUTH_DAKOTA', label: 'South Dakota' },
  { id: 'TN', longId: 'TENNESSEE', label: 'Tennessee' },
  { id: 'TX', longId: 'TEXAS', label: 'Texas' },
  { id: 'UT', longId: 'UTAH', label: 'Utah' },
  { id: 'VT', longId: 'VERMONT', label: 'Vermont' },
  { id: 'VA', longId: 'VIRGINIA', label: 'Virginia' },
  { id: 'WA', longId: 'WASHINGTON', label: 'Washington' },
  { id: 'WV', longId: 'WEST_VIRGINIA', label: 'West Virginia' },
  { id: 'WI', longId: 'WISCONSIN', label: 'Wisconsin' },
  { id: 'WY', longId: 'WYOMING', label: 'Wyoming' },
  { id: 'MX', longId: 'MEXICO', label: 'Mexico' },
  { id: 'CDN', longId: 'CANADA', label: 'Canada' }
]
export default statesOptions
