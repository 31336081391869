import { TableCell, TableRow } from '@mui/material'
import clsx from 'clsx'
import { get } from 'lodash'
import React, { FC, useMemo, useState } from 'react'

import { Chip, Row, Text } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'
import {
  ActionsDropdown,
  CollapseArrow,
  ToggleColumn
} from '@/components/organisms/Table/components'
import { Color } from '@/styles/palette'
import { TableActions, TableColumnType } from '@/types/enums/table'
import { DateFormat, FontWeight, TextTypes } from '@/types/enums/ui'
import {
  ITableActionColumn,
  ITableActionsColumn,
  ITableChipColumn,
  ITableColumn,
  ITableToggleColumn
} from '@/types/interfaces/table'
import { formatDate } from '@/utils/helpers'

import styles from './TableRow.module.scss'

interface IProps {
  row: any
  columns: ITableColumn[]
  idAccessor: string
  clickable?: boolean
}

const CustomTableRow: FC<IProps> = (props) => {
  const { clickable, columns, row, idAccessor } = props
  const { groupBy, handleAction, isRowHighlighted } = useTableContext()

  const highlighted = useMemo(() => isRowHighlighted?.(row), [row])

  const { rows: innerRows } = row

  const [open, setOpen] = useState(false)
  const showInnerRows = !!groupBy && open && !!innerRows?.length

  const renderCell = (
    _row: any,
    column: ITableColumn,
    index: number,
    innerRow?: boolean
  ) => {
    if (groupBy && !innerRow)
      return (
        index === 0 && (
          <Row items="center" gap={8}>
            <CollapseArrow visible active={open} />

            <Text type={TextTypes.TEXT_SM} className="tw-whitespace-nowrap">
              {_row.title}
            </Text>
            <Text
              type={TextTypes.TEXT_MD}
              weight={FontWeight.SEMIBOLD}
              className={styles.groupRowsNumber}
            >
              {innerRows?.length || 0}
            </Text>
          </Row>
        )
      )

    const value =
      typeof column.converter === 'function'
        ? column.converter(_row, column.id, column.friendlyNameMapper)
        : get(_row, column.id)

    switch (column.type) {
      case TableColumnType.Date: {
        return (
          <Text type={TextTypes.TEXT_SM} className="tw-whitespace-nowrap">
            {formatDate(value, DateFormat.DATE_TIME)}
          </Text>
        )
      }

      case TableColumnType.Action: {
        const columnWithAction = column as ITableActionColumn

        return (
          <Text
            type={TextTypes.TEXT_SM}
            color={Color.green500}
            className="tw-whitespace-nowrap hover:tw-underline"
            onClick={(e) => {
              e.stopPropagation()
              handleAction?.(TableActions.ColumnAction, _row)
            }}
          >
            {columnWithAction.actionName}
          </Text>
        )
      }

      case TableColumnType.Actions: {
        const columnWithActions = column as ITableActionsColumn

        const onActionClick = (action: string) => {
          handleAction?.(action as TableActions, _row)
        }

        return (
          <ActionsDropdown
            row={_row}
            actions={columnWithActions.actions}
            onClick={onActionClick}
          />
        )
      }

      case TableColumnType.Toggle: {
        return (
          <ToggleColumn
            row={row}
            column={column as ITableToggleColumn}
            handleAction={handleAction}
          />
        )
      }

      case TableColumnType.Chip: {
        const chipColumn = column as ITableChipColumn

        return (
          <Chip
            size="sm"
            label={chipColumn.getLabel(_row)}
            type={chipColumn.getVariant(_row)}
          />
        )
      }

      default: {
        const isDanger =
          typeof column.danger === 'function' ? column.danger(_row) : false

        return isDanger && value ? (
          <Chip size="sm" label={value} type="error" />
        ) : (
          <Text type={TextTypes.TEXT_SM} className="tw-whitespace-nowrap">
            {value}
          </Text>
        )
      }
    }
  }

  const onRowClick = (_row: any, groupHeader?: boolean) => {
    if (!!groupBy && groupHeader) {
      setOpen(!open)
      return
    }

    if (!clickable) return

    handleAction?.(TableActions.RowClick, _row)
  }

  return (
    <>
      <TableRow
        hover={clickable}
        tabIndex={-1}
        className={clsx(highlighted && styles.highlighted)}
        onClick={(e) => {
          e.stopPropagation()
          onRowClick(row, true)
        }}
      >
        {columns.map((column, index) => (
          <TableCell
            key={`${get(row, idAccessor)}-${column.id}`}
            variant="body"
            align="left"
            className={column.className}
          >
            {renderCell(row, column, index)}
          </TableCell>
        ))}
      </TableRow>

      {showInnerRows &&
        innerRows.map((innerRow: any) => (
          <TableRow
            key={get(innerRow, idAccessor)}
            onClick={() => onRowClick(innerRow)}
            hover={clickable}
            tabIndex={-1}
          >
            {columns.map((column, index) => (
              <TableCell
                key={`${row.id}-${get(innerRow, idAccessor)}-${column.id}`}
                variant="body"
                align="left"
                className={column.className}
              >
                {renderCell(innerRow, column, index, true)}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  )
}

export default CustomTableRow
