import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Col } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { CheckOutSchemaType } from '@/features/gate/utils'
import { CargoAsset } from '@/features/yard/types'

interface IProps {
  siteId: string
}

const CheckOutCargoAsset: FC<IProps> = (props) => {
  const { siteId } = props

  const { reset, trigger } = useFormContext<CheckOutSchemaType>()

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    undefined
  )

  const populateCargoAssetFields = (event: CargoAsset | undefined) => {
    setSelectedEventId(event?.id)

    const { metadata } = event || {}
    const {
      cargo_asset_asset_type,
      cargo_asset_owner_id,
      cargo_asset_carrier_name,
      load_status,
      chassis_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state
    } = metadata || {}

    reset(
      (prev) => ({
        ...prev,

        [FORM_IDS.CARGO_ASSET.TYPE]: cargo_asset_asset_type,
        [FORM_IDS.CARGO_ASSET.OWNER_ID]: cargo_asset_owner_id || '',
        [FORM_IDS.CARGO_ASSET.CARRIER]: cargo_asset_carrier_name || '',
        [FORM_IDS.CARGO_ASSET.LOAD_STATUS]: load_status,
        [FORM_IDS.CARGO_ASSET.CHASSIS_ID]: chassis_id || '',
        [FORM_IDS.CARGO_ASSET.LPN]: cargo_asset_license_plate_number || '',
        [FORM_IDS.CARGO_ASSET.LPN_STATE]: cargo_asset_license_plate_state || ''
      }),
      { keepDirtyValues: true, keepDefaultValues: true, keepSubmitCount: true }
    )

    trigger([
      FORM_IDS.CARGO_ASSET.OWNER_ID,
      FORM_IDS.CARGO_ASSET.LPN,
      FORM_IDS.CARGO_ASSET.LOAD_STATUS
    ])
  }

  return (
    <CargoAssetFormSection>
      {({ isEmpty, isMinimized, isChassisVisible }) => (
        <FormCol>
          {!isMinimized && (
            <>
              <CargoAssetFormSection.Type readOnly />

              <FormRow>
                <CargoAssetFormSection.OwnerId
                  autoComplete
                  siteId={siteId}
                  selectedEventId={selectedEventId}
                  onOptionSelect={populateCargoAssetFields}
                />

                <CargoAssetFormSection.AssetCarrier readOnly />
              </FormRow>
            </>
          )}

          <FormRow>
            <CargoAssetFormSection.LoadStatus />
            {!isEmpty && <CargoAssetFormSection.ShipmentNumber />}
          </FormRow>

          {!isEmpty && (
            <Col items="stretch" gap={4}>
              <CargoAssetFormSection.SealNumbers />
              <CargoAssetFormSection.SealMatchesPW />
            </Col>
          )}

          {isChassisVisible && <CargoAssetFormSection.ChassisId readOnly />}

          {!isMinimized && (
            <FormRow>
              <CargoAssetFormSection.LicensePlateNumber
                autoComplete
                siteId={siteId}
                selectedEventId={selectedEventId}
                onOptionSelect={populateCargoAssetFields}
              />

              <CargoAssetFormSection.LicensePlateState readOnly />
            </FormRow>
          )}
        </FormCol>
      )}
    </CargoAssetFormSection>
  )
}

export default CheckOutCargoAsset
