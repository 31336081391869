import { FC } from 'react'

import { Col, Text } from '@/components/atoms'
import {
  CargoAssetFormSection,
  FormCol,
  FormRow
} from '@/features/forms/components'
import { LaneDirection } from '@/features/gate/enums'
import { getLaneDirectionName } from '@/features/gate/utils'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

interface IProps {
  type: LaneDirection
}

const IsrReportCargoAsset: FC<IProps> = (props) => {
  const { type } = props

  const isArriving = type === LaneDirection.Arriving
  const fieldPrefix = isArriving ? 'check_in' : 'check_out'

  return (
    <CargoAssetFormSection
      alwaysVisible
      withoutWrapper
      fieldPrefix={fieldPrefix}
    >
      <FormCol>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          {getLaneDirectionName(type)}
        </Text>

        <FormRow>
          <CargoAssetFormSection.OwnerId required={false} />
          <CargoAssetFormSection.ShipmentNumber />
        </FormRow>

        <FormRow>
          <CargoAssetFormSection.LicensePlateNumber />
          <CargoAssetFormSection.LicensePlateState />
        </FormRow>

        <Col gap={4}>
          <CargoAssetFormSection.SealNumbers />
          <CargoAssetFormSection.SealMatchesPW />
        </Col>
      </FormCol>
    </CargoAssetFormSection>
  )
}

export default IsrReportCargoAsset
