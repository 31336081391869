export enum UserManagementMessageType {
  UserInvited = 'Invitation successfully sent',
  InvitationCanceled = 'Invitation canceled',
  AccessDisabled = 'User access disabled',
  AccessEnabled = 'User access enabled',
  UserDeleted = 'User successfully deleted',
  UserUpdated = 'Changes successfully saved'
}

export enum UserManagementModalType {
  Delete = 'delete',
  AddEditUser = 'addEditUser',
  CancelInvite = 'cancelInvite'
}
