import { FC, useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'

import { Autocomplete, Input, ReadOnlyFormValue } from '@/components/atoms'
import { DEBOUNCE_TIME } from '@/constants'
import { FORM_IDS } from '@/features/forms/constants'
import { useGetPowerUnitsOnsiteQuery } from '@/features/gate/api'
import { PowerUnitOnsite } from '@/features/gate/types'
import { useStore } from '@/store'
import { ReadOnlyProps } from '@/types/interfaces/ui'

interface IAutocompleteProps {
  siteId: string
  autoComplete: boolean
  selectedEventId: string | undefined
  onOptionSelect: (value: PowerUnitOnsite | undefined) => void
}

const OwnerId: FC<ReadOnlyProps | IAutocompleteProps> = (props) => {
  const { readOnly, customValue, label = 'ID' } = props as ReadOnlyProps
  const { autoComplete, siteId, onOptionSelect, selectedEventId } =
    props as IAutocompleteProps

  const {
    control,
    watch,
    formState: { dirtyFields }
  } = useFormContext()
  const orgId = useStore((store) => store.user.org?.organization_id || '')

  const ownerIdDebounced = useDebounce(
    watch(FORM_IDS.POWER_UNIT.OWNER_ID),
    DEBOUNCE_TIME
  )

  const { data: powerUnitsById, isFetching } = useGetPowerUnitsOnsiteQuery(
    {
      site_id: siteId,
      org_id: orgId,
      type: 'id',
      power_unit_owner_id_prefix: ownerIdDebounced
    },
    { skip: !autoComplete }
  )

  const getOptionLabel = useCallback(
    (option: PowerUnitOnsite) => option?.metadata?.power_unit_owner_id || '',
    []
  )

  const isOptionSelected = useCallback(
    (option: PowerUnitOnsite) => option.id === selectedEventId,
    [selectedEventId]
  )

  // On initial form load, if the Owner ID is already detected by TVE
  // then select the option from the list based on its value
  // That should work only for autocomplete mode and before field was edited by user
  useEffect(() => {
    const isFieldTouched = dirtyFields[FORM_IDS.POWER_UNIT.OWNER_ID]

    if (!autoComplete || !ownerIdDebounced || isFieldTouched) return

    const selectedEvent = powerUnitsById?.find?.(
      (pu) => pu.metadata.power_unit_owner_id === ownerIdDebounced
    )

    if (selectedEvent) {
      onOptionSelect(selectedEvent)
    }
  }, [powerUnitsById])

  if (autoComplete) {
    return (
      <Controller
        name={FORM_IDS.POWER_UNIT.OWNER_ID}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            label={label}
            name={field.name}
            inputValue={field.value}
            options={powerUnitsById || []}
            loading={isFetching}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => field.onChange(newValue)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionSelected}
            onChange={onOptionSelect}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />
    )
  }

  if (readOnly) {
    return (
      <ReadOnlyFormValue
        title={label}
        value={customValue || ownerIdDebounced}
      />
    )
  }

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.OWNER_ID}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          uppercase
          fullWidth
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default OwnerId
