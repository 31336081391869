import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { IInvite } from '@/features/user-management/types'
import { TableActions, TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import { getDateDifference } from '@/utils/helpers'

const invitesColumns = (): ITableColumn[] => [
  {
    id: 'email',
    title: 'Email',
    type: TableColumnType.Text
  },
  {
    id: 'created_date',
    title: 'Invitation Sent',
    type: TableColumnType.Date
  },
  {
    id: 'expiry_date',
    title: 'Expires In',
    type: TableColumnType.Text,
    converter: (row: IInvite) => {
      const days = getDateDifference(new Date(row.expiry_date), new Date())

      if (days < 0) return 'Expired'

      if (days === 0) return 'Today'

      return days === 1 ? '1 day' : `${days} days`
    }
  },
  {
    id: 'actions',
    title: '',
    type: TableColumnType.Actions,
    className: 'tw-w-55',
    actions: [
      {
        id: TableActions.Cancel,
        label: 'Cancel Invite',
        className: 'color-red500',
        icon: <CloseOutlinedIcon />
      }
    ]
  }
]

export default invitesColumns
