import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { ReadOnlyFormValue, Select } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { gateApi } from '@/features/gate/api'
import { useStore } from '@/store'
import { FuelTypes } from '@/types/enums/transactionDetails'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { fuelTypeSelectOptions, fuelTypesFriendlyNames } from '@/utils/mappers'

interface IProps {}

const FuelType: FC<IProps | ReadOnlyProps> = (props) => {
  const { readOnly, customValue, label = 'Fuel Type' } = props as ReadOnlyProps

  const orgId = useStore((store) => store.user.org?.organization_id || '')
  const { control, watch } = useFormContext()

  const { isLoading } = useSelector(
    gateApi.endpoints.getClassAndFuel.select({
      org_id: orgId || '',
      vin: watch(FORM_IDS.POWER_UNIT.VIN)
    })
  )

  const fuelType = (customValue ||
    watch(FORM_IDS.POWER_UNIT.FUEL_TYPE)) as FuelTypes

  if (isLoading) return <ReadOnlyFormValue loading required title={label} />

  if (readOnly)
    return (
      <ReadOnlyFormValue
        title={label}
        value={!!fuelType && fuelTypesFriendlyNames[fuelType]}
      />
    )

  return (
    <Controller
      name={FORM_IDS.POWER_UNIT.FUEL_TYPE}
      control={control}
      render={({ field, fieldState }) => (
        <Select
          {...field}
          required
          label={label}
          options={fuelTypeSelectOptions}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default FuelType
